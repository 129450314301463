<template>
  <div>
    <v-card v-if="!contactInfoEnabled">
      <!-- <pre>{{ tab }}</pre> -->
      <!-- <v-row v-if="idlist">
      <v-col cols="12">
        <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
      </v-col>
    </v-row> -->
      <v-card-text>
        <v-row dense class="mb-0 pb-0">
          <!-- <v-col cols="12" :md="this.$i18n.locale === 'en' ? '2' : '1'">
          <v-text-field
            v-model="filter"
            :append-icon="icons.mdiMagnify"
            label="Search"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-col> -->
          <!-- <v-col cols="12" md="2">
          <v-select
            v-if="!idlist"
            v-model="selectFilter"
            :items="itemsFilter"
            item-text="label"
            dense
            label="Filter"
            outlined
          ></v-select>
        </v-col> -->
          <!-- <v-col cols="12" md="2">
          <v-select
            v-model="selectedChannel"
            :items="filterChannels"
            label="Channel Filter"
            item-text="label"
            item-value="phone_number"
            outlined
            dense
          ></v-select>
        </v-col> -->
          <v-col cols="12" md="2">
            <v-select
              v-model="selectedAction"
              label="Batch Actions"
              single-line
              class="me-2"
              outlined
              item-text="label"
              dense
              :items="actions"
              hide-details
              style="width: 200px"
              @change="selectActions"
            ></v-select>
          </v-col>
          <!-- <v-col v-if="idlist" cols="12" md="auto">
            <v-btn block color="primary" dense @click="openConfig">
              <v-icon dark left>
                {{ icons.mdiCog }}
              </v-icon>
              {{ $t('Profile Config') }}
            </v-btn>
          </v-col> -->
          <v-spacer></v-spacer>
          <!-- <v-col v-if="idlist" cols="12" md="auto">
          <v-btn block color="primary" dense @click="openConfig">
            <v-icon dark left>
              {{ icons.mdiCog }}
            </v-icon>
            {{ $t('contacts.config') }}
          </v-btn>
        </v-col> -->
          <v-col v-if="idlist" cols="12" md="auto">
            <v-btn outlined color="#1F979E" dense @click="importContact">
              <v-icon dark left>
                {{ icons.mdiFileDocument }}
              </v-icon>
              {{ $t('import') }}
            </v-btn>
          </v-col>
          <!-- <v-col cols="12" md="auto">
          <v-btn block color="primary" dense @click="exportTable">
            <v-icon dark left>
              {{ icons.mdiExport }}
            </v-icon>
            <vue-excel-xlsx
              :data="dataContact"
              :columns="columnsExport"
              :file-name="'ContactList'"
              :file-type="'xlsx'"
              :sheet-name="'ContactList'"
              class="primary-text"
            >
              {{ $t('export') }}
            </vue-excel-xlsx>
          </v-btn>
        </v-col> -->
          <v-col cols="12" :md="this.$i18n.locale === 'en' ? '2' : '1'">
            <v-text-field
              v-model="filterByUser"
              :append-icon="icons.mdiMagnify"
              label="Search"
              single-line
              hide-details
              dense
              outlined
              style="width: 300px"
            ></v-text-field>
          </v-col>
          <v-col v-if="idlist" cols="12" md="auto">
            <v-btn block color="primary" dense @click="openNewContact">
              <!-- <v-icon dark left>
              {{ icons.mdiAccountPlus }}
            </v-icon> -->
              {{ $t('Add Contact') }}
            </v-btn>
          </v-col>
          <!-- <v-col v-if="idlist" cols="12" md="auto">
          <v-btn block color="primary" dense @click="openNewContact">
            <v-icon dark left>
              {{ icons.mdiAccountPlus }}
            </v-icon>
            {{ $t('add') }}
          </v-btn>
        </v-col> -->
        </v-row>
        <!-- actions -->
        <!-- <v-row dense class="mt-0 pt-0">
        <v-col cols="12" md="auto">
          <div class="d-flex">
            <v-select
              v-model="selectedAction"
              label="Actions"
              single-line
              class="me-2"
              outlined
              item-text="label"
              dense
              :items="actions"
              hide-details
              :disabled="Boolean(!selectedTableData.length)"
              style="width: 200px"
              @change="selectAction"
            ></v-select>

            <v-tooltip v-if="statusChannelsBanner && bannerAlertText" bottom color="warning">
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" color="warning" v-on="on">
                  {{ icons.mdiAlert }}
                </v-icon>
              </template>
              <span>{{ bannerAlertText }}</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row> -->
      </v-card-text>
      <!-- table -->

      <v-data-table
        v-model="selectedTableData"
        item-key="id"
        :headers="customHeaders"
        :items="contacts"
        :loading="tableLoading"
        :server-items-length="totalContacts"
        loading-text="Loading... Please wait"
        :options.sync="options"
        show-select
        class="text-no-wrap"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPageLabel'),
          'items-per-page-options': [5, 10, 25, 50, 75, 100],
        }"
      >
        <!-- @dblclick:row="openRoomChat" -->
        <!-- <v-data-table
      v-model="selectedTableData"
      item-key="roomId"
      :headers="columnsShow"
      :items="dataContact"
      :options.sync="options"
      :loading="loading"
      show-select
      class="text-no-wrap"
      :footer-props="{
        'items-per-page-text': $t('rowsPerPageLabel'),
        'items-per-page-options': [5, 10, 25, 50, 75, 100],
      }"
    > -->
        <!-- <template #[`item.label`]="{ item }">
        <div v-if="item.label">
          <v-icon
            dark
            :style="{ color: item.label.color }"
          >
            {{ icons.mdiLabel }}
          </v-icon>
          {{ getLabelName(item.label.id) }}
        </div>
      </template> -->
        <template #[`item.hashTag`]="{ item }">
          <div v-for="(el, index) in item.hashTag" :key="index">
            <span>{{ el.hashtag }}</span>
            <v-spacer />
          </div>
        </template>
        <template #[`item.assignTo`]="{ item }">
          <div v-for="(el, index) in item.assignTo" :key="index">
            <span>{{ `${el.channel}: ${el.userEmail}${index === item.assignTo.length - 1 ? '' : ','}` }}</span>
            <v-spacer />
          </div>
        </template>
        <template #[`item.action`]="{ item }">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense icon @click="selectAction('tags', item)">
                <v-icon color="#1F979E">{{ icons.mdiLabelVariant }}</v-icon>
              </v-btn>
              <!-- <v-btn dense icon @click="selectAction('delete', item)">
                <v-icon color="#1F979E">{{ icons.mdiTrashCanOutline }}</v-icon>
              </v-btn> -->
              <v-btn dense icon @click="selectAction('edit', item)">
                <v-icon color="#1F979E">{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
              <v-btn dense icon @click="selectAction('detail', item)">
                <v-icon color="#1F979E">{{ icons.mdiEyeOutline }}</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </template>
        <!-- <template #[`item.action`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" dense icon v-on="on" @click="openEditContact(item)">
              <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ `${$t('edit')} ${$t('history.contact')}` }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" dense icon v-on="on" @click.stop="deleteContact(item)">
              <v-icon>
                {{ idlist ? icons.mdiDeleteOutline : item.archived !== 1 ? icons.mdiArchive : icons.mdiPackageUp }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ `${$t('delete')} ${$t('history.contact')}` }}</span>
        </v-tooltip>
      </template> -->
        <!-- <template #[`item.last_message`]="{ item }">
        {{
          item.last_message
            ? item.last_message._seconds
              ? $moment.unix(item.last_message._seconds / 1000).format('DD-MM-YYYY HH:mm:ss')
              : $moment.unix(item.last_message / 1000).format('DD-MM-YYYY HH:mm:ss')
            : ''
        }}
      </template>
      <template #[`item.created_at`]="{ item }">
        {{ item.created_at ? $moment.unix(item.created_at / 1000).format('DD-MM-YYYY HH:mm:ss') : '' }}
      </template>
      <template #[`item.updated_at`]="{ item }">
        {{ item.updated_at ? $moment.unix(item.updated_at / 1000).format('DD-MM-YYYY HH:mm:ss') : '' }}
      </template>
      <template #[`item.name`]="{ item }">
        {{ getContactsNameRoom(item.phone_number, item.name) }}
      </template> -->
        <template #[`item.tags`]="{ item }">
          <div class="d-flex align-center" style="cursor: pointer" @click="openRoomChat(item)">
            <v-tooltip v-for="(tag, idx) in item.tags" :key="idx" top>
              <template v-slot:activator="{ on, attrs }" v-if="!tag.hide">
                <v-icon fab v-bind="attrs" :color="tag.color" v-on="on">
                  {{ icons.mdiLabelVariant }}
                </v-icon>
              </template>
              <span>Tag Name : {{ tag.name }}</span>
            </v-tooltip>
          </div>
        </template>
        <!-- <template #[`item.pinned`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" @click="editPinned(item)" v-on="on">
              <v-icon v-if="item.pinned">
                {{ icons.mdiPin }}
              </v-icon>
              <v-icon v-else>
                {{ icons.mdiPinOff }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ item.pinned ? `Unpin` : `Pin` }}</span>
        </v-tooltip>
      </template>
      <template #[`item.last_reply`]="{ item }">
        {{
          item.last_reply
            ? item.last_reply._seconds
              ? $moment.unix(item.last_reply._seconds / 1000).format('DD-MM-YYYY HH:mm:ss')
              : $moment.unix(item.last_reply / 1000).format('DD-MM-YYYY HH:mm:ss')
            : ''
        }}
      </template>
      <template #[`item.notes`]="{ item }">
        <v-btn v-if="item.notes && item.notes.length > 0" block color="primary" dense @click="openNotes(item.notes)">
          {{ item.notes.length + ' Notes' }}
        </v-btn>
      </template>
      <template #[`item.label`]="{ item }">
        <div v-for="(data, index) of getListLabel(item)" :key="index">
          <v-icon dark :style="{ color: data.color }">
            {{ icons.mdiLabel }}
          </v-icon>
          {{ data.label }}
        </div>
      </template> -->
        <template #[`item.user`]="{ item }">
          <div class="d-flex align-center" style="cursor: pointer" @click="openRoomChat(item)">
            <div class="d-flex align-center">
              <v-avatar size="40">
                <v-img :lazy-src="defaultProfPic" :src="getProfPic(item.avatar, item)" height="40" width="40"> </v-img>
              </v-avatar>
              <div class="d-flex flex-column ms-3">
                <span
                  style="text-align: left"
                  class="d-block font-weight-semibold text-truncate text--primary phone-number"
                  >{{ item.user.name }}</span
                >
                <!-- <span
              style="text-align: left"
              class="d-block font-weight-semibold text-truncate text--primary phone-number"
              >{{ item.name ? maxTextLength(item.name) : formatPhoneNumber(item.phone_number_show) }}</span
            > -->
                <span style="text-align: left" class="text-xs">
                  <v-avatar style="width: 25px; height: 25px"> <v-img :src="item.platform" contain></v-img> </v-avatar>
                  <span class="ml-n2">{{ item.user.phoneNumber }}</span>
                </span>
                <!-- <span style="text-align: left" class="text-xs">{{
              item.phone_number_show && item.phone_number_show.length > 15 ? 'Group Chat' : item.phone_number_show
            }}</span> -->
              </div>
            </div>
          </div>
        </template>
        <!-- <template #[`item.phone_number_show`]="{ item }">
        <div class="d-flex align-center" style="cursor: pointer" @click="gotoInbox(item)">
          <v-avatar size="40">
            <v-img :lazy-src="defaultProfPic" :src="getProfPic(item.profile_picture, item)" height="40" width="40">
            </v-img>
          </v-avatar>
          <div class="d-flex flex-column ms-3">
            <span
              style="text-align: left"
              class="d-block font-weight-semibold text-truncate text--primary phone-number"
              >{{ item.name ? maxTextLength(item.name) : formatPhoneNumber(item.phone_number_show) }}</span
            >
            <span style="text-align: left" class="text-xs">{{
              item.phone_number_show && item.phone_number_show.length > 15 ? 'Group Chat' : item.phone_number_show
            }}</span>
          </div>
        </div>
      </template> -->
        <template slot="no-data">
          <v-row align="center" class="py-16 padding-hero">
            <v-col class="text-center" lg="6" md="6" sm="12" cols="12">
              <div class="text-start hero-landing">
                <h1 class="hero">
                  {{ $t('contacts.importContact') }}
                </h1>
                <div class="hero-subtitle hide-lottie">
                  <span class="text--primary">{{ $t('contacts.importContactSub') }}</span>
                </div>
                <div class="justify-center try-btn mt-3">
                  <v-btn elevation="2" class="button-goLogin" color="primary" @click="importContact">
                    <span style="color: white">{{ $t('import') }}</span>

                    <v-icon color="white">
                      {{ icons.mdiChevronRight }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col lg="6" md="6" sm="12" cols="12" class="hide-lottie">
              <div class="mx-auto" style="width: 50%">
                <lottie-player
                  src="https://assets9.lottiefiles.com/private_files/lf30_3RMXDE.json"
                  background="transparent"
                  speed="1"
                  class="mx-auto"
                  loop
                  autoplay
                ></lottie-player>
              </div>
            </v-col>
          </v-row>
        </template>
        <!-- <template #[`item.email`]="{ item }">
          <div class="d-flex align-center" style="cursor: pointer" @click="openRoomChat(item)">
          </div>
        </template> -->
        <!-- <template #[`item.assignTo`]="{ item }"> -->
        <!-- <div class="d-flex align-center" style="cursor: pointer" @click="openRoomChat(item)"> -->
        <!-- <div v-if="user.role !== 'Admin' || user.role === 'Member'">
            <div
              v-if="item.assign_to"
              class="d-inline-flex flex-column justify-center ms-3"
            >
              <div class="text--primary font-weight-semibold mb-n1 last-served">
                {{ item.assign_to.email }}
              </div>
              <small class="text--disabled text-capitalize">{{ item.assign_to.name }}</small>
            </div>
          </div>
          <v-menu v-if="user.role === 'Admin'">
            <template v-slot:activator="{ on }">
              <div v-on="on">
                <div
                  v-if="item.assign_to"
                  class="d-inline-flex flex-column justify-center ms-3"
                >
                  <div class="text--primary font-weight-semibold mb-n1 last-served">
                    {{ item.assign_to.email }}
                  </div>
                  <small class="text--disabled text-capitalize">{{ item.assign_to.name }}</small>
                </div>
                <v-icon>{{ icons.mdiChevronDown }}</v-icon>
              </div>
            </template>
            <div class="mx-auto my-auto">
              <v-list dense>
                <v-list-item-group
                  v-model="selectedOperator"
                  color="primary"
                >
                  <v-list-item
                    v-for="operator in userList"
                    :key="operator._id"
                    @change="setOperator(operator, item)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="icons.mdiAccount"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="operator.email">
                        {{ selectedOperator }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-menu> -->

        <!-- <v-select
          v-model="selectedOperator"
          :items="userList"
          item-text="email"
          item-value="email"
          label="Select Operator"
          dense
          hide-details
        ></v-select> -->
        <!-- <v-avatar
          v-if="item.last_interaction"
          size="40px"
          color="primary"
          class="v-avatar-light-bg primary--text last-served-image"
        >
          <img :src="item.last_interaction.image" />
        </v-avatar>
        <div
          v-if="item.last_interaction"
          class="d-inline-flex flex-column justify-center ms-3"
        >
          <span class="text--primary font-weight-semibold mb-n1 last-served">
            {{ item.last_interaction.email }}
          </span>
          <small class="text--disabled text-capitalize">{{ item.last_interaction.name }}</small>
        </div> -->
        <!-- </div> -->
        <!-- </template> -->
      </v-data-table>
      <!-- <v-tab-item>


      <div>
    <v-card-subtitle class="mb-8 mt-10 ">
      <span class="font-weight-semibold text--primary me-1">{{ $t('broadcasts.date') }} :</span>
      <span>{{ dates }}</span>

      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"

        transition="scale-transition"
        bottom
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on">
            <v-icon>
              {{ icons.mdiCalendarMonth }}
            </v-icon>
          </v-btn>
        </template>
        <v-date-picker v-model="dates" range no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn text color="primary" @click="getStatisticChart(dates)"> OK </v-btn>
        </v-date-picker>
      </v-menu>
    </v-card-subtitle>

        <vue-apex-charts type="area" height="300" :options="chartCreatedAt" :series="userDataCreated"></vue-apex-charts>
      </div>
        <v-row>
          <v-col cols="4" v-for="chart in blockColums" :key="chart">
            <v-card class="ms-3 my-3">
              <v-card-title>
               {{chart}}
              </v-card-title>
               <vue-apex-charts
              type="donut"
              height="350"
              :options="dataChart[chart].chartOptions"
              :series="dataChart[chart].series"
            />
            </v-card>
          </v-col>
        </v-row>



      </v-tab-item>
    </v-tabs-items> -->

      <!-- dialog config -->
      <v-dialog v-model="dialogNotes" persistent width="600px">
        <v-card>
          <v-card-actions>
            <h3 class="font-weight-bold">
              {{ $t('notes') }}
            </h3>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-divider />

          <v-card-text class="heigth-dialog">
            <div v-for="(note, index) of notes" :key="index">
              <v-row>
                <v-col cols="12" md="auto">
                  {{ 1 + index + '. ' }}
                </v-col>
                <v-col cols="12" md="10">
                  <span v-html="urlify(note.name)"></span>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ffeeee" class="mr-3" @click="dialogNotes = false">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- dialog config -->
      <v-dialog v-model="dialogConfig" width="689px">
        <v-card width="689px">
          <v-card-actions>
            <h3 class="font-weight-bold">{{ $t('Profile') }} {{ $t('contacts.config') }}</h3>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-divider />

          <v-row>
            <v-col align="center" justify="center">
              <v-card color="#F4F4F4" width="590px" class="m-8 elevation-0">
                <v-container>
                  <v-layout>
                    <v-flex>
                      <v-card color="white" class="my-6" width="504px">
                        <v-row>
                          <v-col align="center" justify="center" class="my-6">
                            <h5 class="font-weight-bold text-start ml-4 mb-2">
                              1. {{ $t('Please enter your name') }}: *
                            </h5>
                            <v-text-field
                              :rules="[requiredRules]"
                              required
                              outlined
                              dense
                              hide-details
                              class="mx-6"
                              type="text"
                              placeholder="John Smith"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card color="white" class="my-6" width="504px">
                        <v-row>
                          <v-col align="center" justify="center" class="my-6">
                            <h5 class="font-weight-bold text-start ml-4 mb-2">2. {{ $t('Your birthdate') }}: *</h5>
                            <v-text-field
                              :rules="[requiredRules]"
                              required
                              outlined
                              dense
                              hide-details
                              class="mx-6"
                              type="date"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-card color="white" class="my-6" width="504px">
                        <v-row>
                          <v-col align="center" justify="center" class="my-6">
                            <h5 class="font-weight-bold text-start ml-4 mb-2">3. {{ $t('Your e-mail') }}: *</h5>
                            <v-text-field
                              :rules="[requiredRules]"
                              required
                              outlined
                              dense
                              hide-details
                              class="mx-6"
                              type="email"
                              placeholder="JohnSmith@gmail.com"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ffeeee" class="" @click="dialogConfig = false">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>

            <v-btn color="#1F979E" class="text-white" @click="saveConfig">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- dialog add contact -->
      <v-dialog v-model="dialogContact" persistent width="600px">
        <v-card>
          <v-card-actions>
            <h3 class="font-weight-bold">
              {{ (isEdit ? $t('edit') : $t('add')) + ' ' + $t('contacts.contact') }}
            </h3>
            <v-spacer></v-spacer>
            <!-- <v-btn color="error" class="mr-3 hide-buton" @click="dialogContact = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="success" class="hide-buton" @click="saveContact">
            {{ $t('save') }}
          </v-btn> -->
          </v-card-actions>
          <v-divider />

          <v-card-text>
            <v-form>
              <v-row>
                <v-col cols="12" class="my-0 py-0 px-5">
                  <v-text-field
                    v-model="model.contactName"
                    :rules="[validators.required]"
                    class="mt-4"
                    outlined
                    dense
                    :label="`${$t('Name')}`"
                    @click="checkEmptyName"
                    :error-messages="errorMessages"
                  />
                  <v-text-field
                    v-model="photoLinks"
                    outlined
                    dense
                    :label="`${$t('Avatar URL')}`"
                    @click="openFileManager"
                    readonly
                  />
                  <v-text-field v-model="model.nickname" outlined dense :label="`${$t('Nickname')}`" />

                  <!-- <v-text-field v-model="model.phoneNumber" outlined dense :label="`${$t('Phone')} / WA`" /> -->
                  <template>
                    <v-combobox
                      v-model="model.phoneNumber"
                      chips
                      clearable
                      label="Phone Number"
                      :rules="requiredRules"
                      multiple
                      outlined
                      dense
                      deletable-chips
                      @click="checkEmptyPhone"
                      :error-messages="errorMessagesPhone"
                    >
                      <template v-slot:selection="{ phones, item, select, selectedPhones }">
                        <v-chip
                          v-bind="phones"
                          clearable
                          :input-value="selectedPhones"
                          close
                          :rules="requiredRules"
                          @click="select"
                          @click:close="remove(item)"
                        >
                          <strong>{{ item }}</strong>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </template>

                  <template>
                    <v-combobox
                      v-model="model.email"
                      chips
                      :rules="[validators.email]"
                      clearable
                      label="Email"
                      multiple
                      outlined
                      dense
                      deletable-chips
                    >
                      <template v-slot:selection="{ emails, item, select, selectedEmail }">
                        <v-chip
                          v-bind="emails"
                          clearable
                          :input-value="selectedEmail"
                          close
                          @click="select"
                          @click:close="remove(item)"
                        >
                          <strong>{{ item }}</strong>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </template>

                  <!-- <v-text-field v-model="model.tags" outlined dense :label="`${$t('Tags')}`" /> -->
                  <template>
                    <v-select
                      v-model="selectedTags"
                      :items="selectedTags"
                      :menu-props="{ top: false, offsetY: true, display: 'none' }"
                      dense
                      chips
                      deletable-chips
                      label="Add Labels"
                      multiple
                      outlined
                      no-data-text
                      readonly
                      @click="openSelectTags"
                    ></v-select>
                  </template>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider class="" />
          <v-card-actions class="text-end">
            <v-spacer></v-spacer>
            <v-btn class="mr-3" @click="closeDialogContact">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn color="primary" class="" @click="saveContact">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
          <v-snackbar v-model="errorTitleValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
            Please fill out all fields and make sure you enter a valid email & phone number is required
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="errorTitleValidationSnackbar = false"> {{ $t('Close') }} </v-btn>
            </template>
          </v-snackbar>
          <v-snackbar
            v-model="errorPhoneValidationSnackbar.status"
            color="error"
            :top="true"
            style="z-index: 5 !important"
          >
            Your Phone Number {{ errorPhoneValidationSnackbar.phone }} Already Registered
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="errorPhoneValidationSnackbar = false"> {{ $t('Close') }} </v-btn>
            </template>
          </v-snackbar>
        </v-card>
      </v-dialog>

      <!-- dialog alert -->
      <v-dialog v-model="dialogAlert" width="500">
        <v-card>
          <v-card-title color="error">
            {{ $t('warning') }}
          </v-card-title>
          <v-divider class="" />

          <v-card-text>
            {{ alertText }}
          </v-card-text>
          <v-divider class="" />

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="dialogAlert = false">
              {{ $t('close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- dialog batch assign -->
      <v-dialog v-model="dialogAssign" persistent width="600px">
        <v-card>
          <v-card-actions>
            <h3 class="font-weight-bold">Assign Contacts</h3>
            <v-spacer></v-spacer>
            <!-- <v-btn color="error" class="mr-3 hide-buton" @click="dialogContact = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="success" class="hide-buton" @click="saveContact">
            {{ $t('save') }}
          </v-btn> -->
          </v-card-actions>
          <v-divider />

          <v-card-text>
            <v-form>
              <v-row>
                <v-col cols="12" class="my-2 py-3 px-5">
                  <v-select
                    v-model="chosenUser"
                    :items="listUsers"
                    item-text="email"
                    item-value="id"
                    dense
                    label="Assign"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider class="" />
          <v-card-actions class="text-end">
            <v-spacer></v-spacer>
            <v-btn class="mr-3" @click="closeDialogAssignContact">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn color="primary" class="" @click="saveAssignContact">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- dialog confirmation -->
      <confirm ref="confirm"></confirm>

      <!-- dialog select label -->
      <selectLabel ref="selectLabel"></selectLabel>

      <!-- dialog select list -->
      <dialogSelectList ref="dialogSelectList"></dialogSelectList>

      <!-- dialog select list -->
      <dialogSelectOperator ref="dialogSelectOperator"></dialogSelectOperator>

      <!-- dialog select label -->
      <dialogImport
        ref="dialogImport"
        :selected="selected"
        :selectedTags="selectedTags"
        :allTags="allTags"
        :isCancelled="isCancelled"
        @selected-tags="saveSelectedTags"
        @import-is-cancelled="importIsCancelled"
      >
      </dialogImport>

      <!-- dialog select label -->
      <dialogAddListContact ref="dialogAddListContact"></dialogAddListContact>

      <dialogProfileConfig ref="dialogProfileConfig"></dialogProfileConfig>

      <!-- dialog Add Survey form response -->
      <dialogAddSurvey ref="dialogAddSurvey" @save-response="toFormResponse"></dialogAddSurvey>

      <!-- dialog batch edit -->
      <dialogBatchEdit
        ref="dialogBatchEdit"
        :selected="selected"
        :selectedTags="selectedTags"
        :allTags="allTags"
        :tableLoading="tableLoading"
        @fetch-contact="fetchContacts"
        @open-tags="openSelectTags"
        @remove-chips="remove"
        @selected-tags="saveSelectedTags"
      ></dialogBatchEdit>

      <!-- addBroadcast Dialog -->
      <v-dialog v-model="addBroadcastDialog" width="900" persistent scrollable>
        <template v-slot:default="">
          <v-card elevation="0">
            <v-toolbar color="primary">
              <div class="text-h5 white--text">
                {{ $t('broadcasts.createBroadcast') }}
              </div>
              <v-spacer></v-spacer>
              <v-btn class="pa-8" icon @click="closeDialog(false)">
                <v-icon x-large color="white">
                  {{ icons.mdiCloseCircle }}
                </v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <add-broadcast
                :key="newBroadcast"
                :from-contacts="true"
                :data-contacts-from-api="dataContactsFromApi"
                :contacts-from-wa="selectedTableData"
                @close-dialog="closeDialog(true)"
              ></add-broadcast>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>

      <dialogSetTags
        ref="dialogSetTags"
        @selected-tags="saveSelectedTags"
        :contacts="contacts"
        :isCancelled="isCancelled"
        @fetch-contact="fetchContacts"
      ></dialogSetTags>

      <!-- <pre>{{dataListContact2}}</pre> -->
      <v-snackbar v-model="errorPhoneValidationSnackbar.status" color="error" :top="true" style="z-index: 5 !important">
        Your Phone Number {{ errorPhoneValidationSnackbar.phone }} Already Registered
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="errorPhoneValidationSnackbar = false"> {{ $t('Close') }} </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="importContactInfo.status" color="success" :top="true" style="z-index: 5 !important">
        {{ importContactInfo.data }}
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="importContactInfo.status = false"> {{ $t('Close') }} </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="errorWAValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
        Sessions not found on your workspace.
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="errorWAValidationSnackbar = false"> {{ $t('Close') }} </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
import { TimeFormater } from '@/helpers'
import { default as clientPocketBase } from '@/helpers/pocketbase'
import {
  mdiAccount,
  mdiAccountOutline,
  mdiAccountPlus,
  mdiAlert,
  mdiArchive,
  mdiArrowLeftThick,
  mdiCalendar,
  mdiCalendarMonth,
  mdiCartOutline,
  mdiChartBar,
  mdiChevronDown,
  mdiChevronRight,
  mdiCloseCircle,
  mdiCloudUpload,
  mdiCog,
  mdiContentSave,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiDragVertical,
  mdiExport,
  mdiEyeOutline,
  mdiFileDocument,
  mdiFilter,
  mdiFilterOff,
  mdiFolderOutline,
  mdiFolderPlus,
  mdiImport,
  mdiLabel,
  mdiLabelVariant,
  mdiMagnify,
  mdiPackageUp,
  mdiPencilOutline,
  mdiPin,
  mdiPinOff,
  mdiPlusCircle,
  mdiStar,
  mdiStarOutline,
  mdiTrashCan,
  mdiTrashCanOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import PouchDB from 'pouchdb-browser'

import { emailValidator, required } from '@core/utils/validation'
import VueApexCharts from 'vue-apexcharts'
import draggable from 'vuedraggable'
import contactMixin from '../../mixins/contactMixin'
import firestoreDbMixin from '../../mixins/firestoreDbMixin'

// import custom
import '@/plugins/vue-advanced-chat-single/styles/vue-advanced-chat.css'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import utilsMixin from '../../mixins/utilsMixin'
import AddBroadcast from '../broadcast/AddBroadcast.vue'
import confirm from '../component/Confirm.vue'
import dialogAddListContact from '../component/DialogAddListContact.vue'
import dialogAddSurvey from '../component/DialogAddSurvey.vue'
import dialogBatchEdit from '../component/DialogBatchEdit.vue'
import dialogImport from '../component/DialogImport.vue'
import dialogProfileConfig from '../component/DialogProfileConfig.vue'
import selectLabel from '../component/DialogSelectLabel.vue'
import dialogSelectList from '../component/DialogSelectList.vue'
import dialogSelectOperator from '../component/DialogSelectOperator.vue'
import dialogSetTags from '../component/DialogSetTags.vue'

import fileManagerOptions from '@/views/MyAssets/options/optionsFileManager.js'
import axios from 'axios'
import { fileManagerModal } from 'mini-file-manager'
// eslint-disable-next-line import/no-unresolved
PouchDB.adapter('worker', require('worker-pouch'))

function wrapCsvValue(val, formatFn) {
  let formatted = formatFn !== void 0 ? formatFn(val) : val

  formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

  formatted = formatted.split('"').join('""')

  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
  setup() {
    return {
      validators: {
        required,
        emailValidator,
      },
    }
  },
  name: 'Contacts',
  components: {
    draggable,
    confirm,
    selectLabel,
    dialogSelectList,
    dialogSelectOperator,
    dialogImport,
    dialogAddListContact,
    AddBroadcast,
    dialogBatchEdit,
    VueApexCharts,
    dialogSetTags,
    dialogProfileConfig,
    dialogAddSurvey,
  },
  mixins: [firestoreDbMixin, pouchDbMixin, contactMixin, utilsMixin],
  props: { contactInfoEnabled: { type: Boolean, required: false } },
  data() {
    return {
      actionItems: [
        { title: 'Set Labels', value: 'tags' },
        { title: 'Edit', value: 'edit' },
        { title: 'Show Detail', value: 'detail' },
        { title: 'Delete', value: 'delete' },
      ],
      customHeaders: [
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Assign To',
          value: 'assignTo',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Labels',
          value: 'tags',
        },
        {
          text: 'Campaign',
          value: 'hashTag',
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false,
        },
      ],
      selectedTags: [],
      selectedColorTags: [],
      allTags: [],
      selected: {},
      // contactInfoEnabled: false,
      contacts: [],
      totalContacts: 0,

      customDataContacts: [
        {
          user: {
            name: 'John Doe',
            phoneNumber: '+6281234567890',
          },
          assignTo: '',
          email: 'jbellany9@kickstarter33',
          tags: [
            {
              name: 'test',
              color: 'warning',
            },
          ],
        },
        {
          user: {
            name: 'Wawan Winata',
            phoneNumber: '+6281234567890',
          },
          assignTo: '',
          email: 'jbellany9@kickstarter33',
          tags: [
            {
              name: 'test',
              color: 'primary',
            },
          ],
        },
      ],
      filterChannels: [
        {
          label: 'All Channels',
          phone_number: 'all',
        },
      ],
      selectedChannel: 'all',
      couchAllContact: [],
      contactListTableAll: [],
      rangeDates: [],
      userDataCreated: [{ data: [], name: 'Data Inserted' }],
      userDataCreatedShow: [],
      dates: [
        new Date(new Date().setDate(new Date().getDate() - 5)).toISOString().substring(0, 10),
        new Date().toISOString().substring(0, 10),
      ],
      dataContactsFromApi: null,
      chartCreatedAt: {},
      countingAnswer: {},
      dataChart: {},
      donutChart: {
        series: [0, 0],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: '"Inter", sans-serif',
          },
          colors: ['#ffe700', '#00d4bd'],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          labels: ['Response', 'No Response'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      tab: ref(''),
      menu: false,
      tabs: [{ title: 'Table' }, { title: 'Summary' }],
      today: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
      listContactValue: [],
      labelData: [],
      contactListSetting: null,
      contactListTable: [],
      isRtl: '',
      icons: {
        mdiCalendarMonth,
        mdiCog,
        mdiPin,
        mdiAlert,
        mdiPinOff,
        mdiCartOutline,
        mdiAccountOutline,
        mdiLabel,
        mdiLabelVariant,
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlusCircle,
        mdiExport,
        mdiChartBar,
        mdiEyeOutline,
        mdiDragVertical,
        mdiAccountPlus,
        mdiImport,
        mdiFileDocument,
        mdiFilter,
        mdiFilterOff,
        mdiTrashCan,
        mdiCalendar,
        mdiContentSave,
        mdiCloseCircle,
        mdiArchive,
        mdiPackageUp,
        mdiStar,
        mdiStarOutline,
        mdiChevronDown,
        mdiAccount,
        mdiChevronRight,
        mdiDotsVertical,
        mdiArrowLeftThick,
        mdiTrashCanOutline,
        mdiFolderOutline,
        mdiFolderPlus,
        mdiCloudUpload,
      },
      itemsBreadcrumbs: [
        {
          text: 'Contact',
          disabled: false,
          exact: true,
          link: true,
          to: {
            name: 'contacts-group-list',
          },
        },
      ],
      filter: '',
      selectFilter: null,
      showFilter: false,
      filterValue: [],
      filterValue2: [],
      filterOperator: [],
      selectedOperator: '',
      menu: [],
      menu2: [],
      itemFilterColumn: [
        '',
        'equals',
        'not equals',
        'contains',
        'not contains',
        'starts with',
        'finishes with',
        'is empty',
        'is not empty',
      ],
      itemFilterColumnDate: [
        '',
        'on',
        'not on',
        'after',
        'before',
        'between',
        'not between',
        'is empty',
        'is not empty',
      ],

      columnsSetting: null,
      settingFilterSetting: null,
      tableColumns: [],
      itemsFilter: ['all', 'assigned', 'unread'],
      labelValue: [],
      labelSetting: [],
      filterByUser: '',
      errorTitleValidationSnackbar: false,
      errorPhoneValidationSnackbar: { status: false, phone: '' },

      // contactListTable: [],
      totalContact: 0,
      // options: {
      //   sortBy: ['last_message'],
      //   sortDesc: [true],
      // },
      options: {},
      selectedTableData: [],
      loading: false,
      selectedAction: '',
      actions: [
        { label: this.$t('Set Labels'), value: 'tags' },
        { label: this.$t('Assign'), value: 'assign' },
        // { label: this.$t('Delete'), value: 'delete' },
        { label: this.$t('Grouping'), value: 'grouping' },
        { label: this.$t('Add Form'), value: 'addForm' },
      ],

      // DIALOG CONFIG
      listDraggable: [],
      dialogConfig: false,

      // DIALOG ALERT
      alertText: false,
      dialogAlert: false,

      // DIALOG CONTACT
      isEdit: false,
      dialogContact: false,
      model: {
        avatar: '',
        contactName: '',
        phoneNumber: [],
        nickname: '',
        email: [],
        tags: [],
      },

      // DIALOG ADD FILTER
      filterName: '',

      // DIALOG BROADCAST
      addBroadcastDialog: false,
      newBroadcast: 1,

      // DIALOG NOTES
      dialogNotes: false,
      notes: [],

      // Charts
      blockColums: [],
      countingAction: [],
      tableLoading: false,
      contactIdResponse: {
        id: [],
        name: '',
      },
      photoLinks: '',
      errorWAValidationSnackbar: false,
      isCancelled: false,
      importContactInfo: {
        status: false,
        data: '',
      },
      listUsers: [],
      chosenUser: '',
      dialogAssign: false,
      requiredRules: [value => (value && value.length <= 10) || required(value)],
      valueRequired: '',
      errorMessages: [],
      errorMessagesPhone: [],
      listInstances: [],
    }
  },
  emits: ['selected-tags'],
  computed: {
    bannerAlertText() {
      return this.$store.getters['inbox/getBannerAlertText']
    },
    statusChannelsBanner() {
      return this.$store.getters['global/getStatusChannels']
    },
    // listInstances() {
    //   const inst = this.$store.getters['auth/getListInstance']
    //   if (inst) {
    //     return inst
    //   }

    //   return []
    // },

    // dateRangeText() {
    //   let data = JSON.parse(JSON.stringify(this.dates))
    //   // data.join(' ~ ')
    //   return `${data[0]} ~ ${data[1]}`
    // },
    defaultProfPic() {
      return `${process.env.VUE_APP_WA_API_URL}ppic/@whatsapp.net/2414214242414/a.jpg`
    },
    allSettings() {
      return this.$store.getters['global/getSettings']
    },

    contactVuex() {
      if (this.idlist) {
        return this.$store.getters['global/getContacts'].filter(x => x._id.startsWith(this.idlist))
      }

      return this.$store.getters['global/getContacts'].filter(x => x._id === x.phone_number)
    },
    idlist() {
      return true

      // return this.$route.params.idlist
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    userList() {
      return this.$store.getters['auth/getListUsers']
    },
    columnsAdditionalEdit() {
      const tableFilter = this.selectionSort(this.tableColumns.filter(x => x.deleteable || x.value === 'name'))

      return tableFilter
    },
    columnsEditable() {
      const tableFilter = this.selectionSort(
        this.tableColumns.filter(
          x => x.value !== 'id' && x.value !== 'label' && x.value !== 'phone_number' && x.value !== 'status',
        ),
      )

      return tableFilter
    },
    columnsExport() {
      let table = this.tableColumns.filter(x => x.show && x.value !== 'status')
      const tableFilter = this.selectionSort(table)
      table = tableFilter

      return table
    },
    columnsShow() {
      this.tableColumns.forEach(el => {
        if (el.text === 'Label') {
          el.show = true
        }
      })
      let table = JSON.parse(JSON.stringify(this.tableColumns)).filter(
        x =>
          x.show &&
          x.value !== 'status' &&
          x.value !== 'source' &&
          x.value !== 'last_interaction' &&
          x.value !== 'level_engagement' &&
          x.value !== 'unreplied' &&
          x.value !== 'subscribe_broadcast',
      )

      // .sort((a, b) => (a.order_number < b.order_number ? -1 : 0))
      const tableFilter = this.selectionSort(table)
      table = tableFilter
      table.forEach(item => {
        if (
          item.field != 'action' &&
          item.field != 'name' &&
          item.field != 'phone_number' &&
          item.field != 'phone_number_show'
        ) {
          item.filterable = false
        } else {
          item.filterable = true
        }

        if (item.text === 'Name') {
          item.text = this.$t('name')
        } else if (item.text === 'Phone Number') {
          item.text = this.$t('channels.phoneNumber')
        } else if (item.text === 'Last Reply') {
          item.text = this.$t('lastReply')
        } else if (item.text === 'Notes') {
          item.text = this.$t('notes')
        } else if (item.text === 'Last Message') {
          item.text = this.$t('lastMessage')
        } else if (item.text === 'Favourite') {
          item.label = this.$t('pinned')
          item.text = this.$t('pinned')
          item.field = 'pinned'
          item.name = 'pinned'
          item.value = 'pinned'
        } else if (item.text === 'Interaction') {
          item.text = this.$t('interaction')
        } else if (item.text === 'Need Reply') {
          item.text = this.$t('needReply')
        } else if (item.text === 'Assign To') {
          item.text = this.$t('assignTo')
        } else if (item.text === 'Message Status') {
          item.text = this.$t('messageStatus')
        }
      })

      // table.push({
      //   name: 'Created At',
      //   align: 'center',
      //   label: '',
      //   field: '',
      //   value: 'created_at',
      //   text: 'Created at',
      //   sortable: true,
      //   type: 'text',
      //   show: false,
      //   showable: true,
      //   formatDate: '',
      //   deleteable: true,
      //   isRequired: false,
      //   defaultValue: '',
      //   editable: true,
      //   order_number: this.tableColumns.length + 1,
      // })
      // table.push({
      //   name: 'Updated At',
      //   align: 'center',
      //   label: '',
      //   field: '',
      //   value: 'created_at',
      //   text: 'Created at',
      //   sortable: true,
      //   type: 'text',
      //   show: false,
      //   showable: true,
      //   formatDate: '',
      //   deleteable: true,
      //   isRequired: false,
      //   defaultValue: '',
      //   editable: true,
      //   order_number: this.tableColumns.length + 1,
      // })

      return table
    },
    columnsAdditional() {
      return this.tableColumns.filter(x => x.deleteable).sort((a, b) => (a.order_number < b.order_number ? -1 : 0))
    },
    dataListContact2() {
      return this.listContactValue
    },
  },
  watch: {
    selectFilter() {
      this.actions = this.idlist
        ? [
            // { label: this.$t('delete'), value: 'delete' },
            // { label: this.$t('edit'), value: 'edit' },
            { label: this.$t('Set Labels'), value: 'tags' },
            { label: this.$t('Assign'), value: 'assign' },
            // { label: this.$t('Delete'), value: 'delete' },
            { label: this.$t('Grouping'), value: 'grouping' },
            { label: this.$t('Add Form'), value: 'addForm' },
          ]
        : this.user.role === 'Admin'
        ? [
            // {
            //   label:
            //     this.selectFilter && (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
            //       ? this.$t('unarchived')
            //       : this.$t('archive'),
            //   value: 'delete',
            // },
            // { label: this.$t('edit'), value: 'edit' },
            // { label: this.$t('setLabel'), value: 'label' },
            // { label: `${this.$t('send')} Broadcast`, value: 'send' },
            // { label: `${this.$t('addTo')} Contact List`, value: 'add' },
            // { label: this.$t('assignTo'), value: 'assign' },

            { label: this.$t('Set Labels'), value: 'tags' },
            { label: this.$t('Assign'), value: 'assign' },
            // { label: this.$t('Delete'), value: 'delete' },
            { label: this.$t('Grouping'), value: 'grouping' },
            { label: this.$t('Add Form'), value: 'addForm' },

            // { label: this.$t('inbox.deleteRoom'), value: 'deleteContact' },
          ]
        : [
            // {
            //   label:
            //     this.selectFilter && (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
            //       ? this.$t('unarchived')
            //       : this.$t('archive'),
            //   value: 'delete',
            // },
            // { label: this.$t('edit'), value: 'edit' },
            // { label: this.$t('setLabel'), value: 'label' },
            // { label: `${this.$t('send')} Broadcast`, value: 'send' },
            // { label: `${this.$t('addTo')} Contact List`, value: 'add' },

            { label: this.$t('Set Labels'), value: 'tags' },
            { label: this.$t('Assign'), value: 'assign' },
            // { label: this.$t('Delete'), value: 'delete' },
            { label: this.$t('Grouping'), value: 'grouping' },
            { label: this.$t('Add Form'), value: 'addForm' },

            // { label: this.$t('inbox.deleteRoom'), value: 'deleteContact' },
          ]
    },
    selectedTableData() {
      if (this.selectedTableData.length === 0) {
        this.selectedAction = 0
      }
    },
    filterByUser() {
      this.fetchContacts()
    },
    'model.contactName'(value) {
      // console.log(value, '<<Tessss')
      this.validators.required = required(value)
      if (value) {
        this.valueRequired = value
        this.errorMessages = []
      }
    },
    'model.phoneNumber'(value) {
      // console.log(value, '<<Tessss')
      this.requiredRules = [required(value)]
      if (value) {
        this.valueRequiredPhone = value
        this.errorMessagesPhone = []
        // } else {
        //   this.errorMessagesPhone = ['This field is required']
      }
    },
    options: {
      handler() {
        // this.getDataFromApi()
        this.fetchContacts()
      },
      deep: true,
    },
  },
  beforeDestroy() {
    // this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
    // this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_CONTACTS)
  },
  created() {
    if (this.idlist === 'return') {
      this.$router.go(-2)
    }
  },
  async mounted() {
    const instances = await this.$store.dispatch('auth/getAllInstances')
    this.listInstances = instances?.value || []
    if (!this.contacts.length) {
      this.fetchContacts()
    }
    try {
      const users = await clientPocketBase.collection('users').getFullList(200, {
        $autoCancel: false,
        filter: `sub_id = '${this.user.sub_id}'`,
      })
      this.listUsers = users
    } catch (err) {}
    clientPocketBase.collection('crm_contacts').subscribe('*', e => this.fetchContacts())
    // clientPocketBase.collection('crm_tags').subscribe('*', e => this.fetchContacts())
  },
  methods: {
    checkEmptyName() {
      // console.log(this.valueRequired, this.validators.required, 'viraa')
      if (!this.valueRequired) {
        this.errorMessages = ['This field is required']
        this.validators.required = 'This field is required'
      } else {
        this.errorMessages = []
      }
    },
    checkEmptyPhone() {
      // console.log(this.valueRequiredPhone, this.requiredRules, 'viraa')
      if (!this.valueRequiredPhone) {
        this.errorMessagesPhone = ['This field is required']
        this.requiredRules = ['This field is required']
      } else {
        this.errorMessagesPhone = []
      }
    },
    async openRoomChat(item) {
      const phone = item.user.phoneNumber
      const getAllContactByPhone = await clientPocketBase.collection('crm_contact').getFullList(200, {
        expand: 'assign_to',
        filter: `phone_number = '${phone}'`,
        $autoCancel: false,
      })
      let isPushed = false
      let isSessionsExist = false
      let getRoomInstances
      let roomId = ''
      if (getAllContactByPhone?.length) {
        for (let i = 0; i < getAllContactByPhone.length; i++) {
          const room = getAllContactByPhone[i]
          try {
            getRoomInstances = await clientPocketBase
              .collection('crm_instances')
              .getFirstListItem(`id = '${room.instance}' && status = 1`, { $autoCancel: false })
          } catch (err) {}
          if (getRoomInstances) {
            const formData = new FormData()
            const sessions = this.user.sub_id + '-' + getRoomInstances._id
            const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}sessions/find/${sessions}`
            // formData.append('id', room.contac_id)
            try {
              const config = {
                headers: {
                  'content-type': 'multipart/form-data',
                },
              }
              const { data } = await this.$axios.get(endpoint, formData, config)
              if (data.success) {
                isSessionsExist = true
              }
            } catch (err) {
              console.log('🚀 ~ file: Chat.vue ~ line 3338 ~ sendMessage ~ err', err)
            }
            if (isSessionsExist) {
              this.$router.push({ name: 'inbox', query: { roomId: room.roomId } })
              isPushed = true
              break
            } else {
              roomId = room.roomId
            }
          }
        }
        if (!isSessionsExist) {
          this.$router.push({ name: 'inbox', query: { roomId: roomId } })
          // this.errorWAValidationSnackbar = true
        }
      } else {
        if (!isPushed) {
          // const getOneInstance = await clientPocketBase
          //   .collection('crm_instances')
          //   .getFirstListItem('inbox_id="" && status=1')
          // const instance = getOneInstance._id
          // const id = this.$Md5Hash(getOneInstance.id + '-' + phone)
          // const createContact = await clientPocketBase.collection('crm_contact').create({
          //   id,
          //   contact_id: instance + '-' + phone,
          //   roomId: instance + '-' + phone,
          //   type: 'rooms',
          //   source: 'internal',
          //   instance: getOneInstance.id,
          //   phone_number: phone,
          //   name: item.user.name,
          //   nickname: item.nickname,
          //   newchat_timestamp: new Date().getTime(),
          //   phone_number_show: phone.length > 16 ? 'Group' : phone,
          //   profile_picture: item.avatar,
          //   sync_firestore: false,
          //   unread_count: 0,
          //   pinned: false,
          //   subId: this.user.sub_id,
          // })
          this.$router.push({ name: 'inbox', query: { phone_number: phone, name: item.user?.name || phone } })
        }
      }
    },
    openFileManager() {
      fileManagerModal(fileManagerOptions(this.user.sub_id), this.saveFile)
    },
    closeDialogContact() {
      this.photoLinks = ''
      this.errorMessages = []
      this.errorMessagesPhone = []
      this.requiredRules = [true]
      this.validators.required = true
      this.dialogContact = false
    },
    async saveFile(data) {
      //console.log('sayang', data);
      const images = Promise.all(
        data.map(async el => {
          const res = 'https://fm.prod.marketa.id/uploads/' + el.uploadRelativePath
          this.photoLinks = res
        }),
      )
    },
    async saveSelectedTags(rawData, tags, data) {
      // console.log(rawData, tags, '<<<<vira save selcetd')
      let tagsName = []
      if (data) {
        try {
          const phone = data.phone_number

          const getAllContactByPhone = await clientPocketBase.collection('crm_contact').getFullList(200, {
            expand: 'assign_to',
            filter: `phone_number = '${phone}'`,
            $autoCancel: false,
          })
          for (let i = 0; i < getAllContactByPhone.length; i++) {
            const idContact = getAllContactByPhone[i].id
            const updateContact = await clientPocketBase
              .collection('crm_contact')
              .update(idContact, { contacts: newContact.id }, { $autoCancel: false })
          }
        } catch (err) {
          console.log(err)
        }
      }

      const selected = Object.keys(rawData)
      for (let index = 0; index < selected.length; index++) {
        const tagId = selected[index]
        if (rawData[selected[index]] !== null) {
          const getOneTag = await clientPocketBase.collection('crm_tags').getOne(tagId, { $autoCancel: false })
          tagsName.push(getOneTag.name)
        }
      }
      this.selected = rawData
      this.selectedTags = tagsName
      this.allTags = tags
      this.$emit('selected-tags', selected)
    },
    async fetchContacts() {
      try {
        this.tableLoading = true
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        let sign = ''
        if (sortDesc?.length) {
          sign = sortDesc[0] === true ? '-' : '+'
        }
        let sort = ''
        if (sortBy.length) {
          switch (sortBy[0]) {
            case 'user':
              sort = 'name'
              break
            case 'email':
              sort = 'email'
              break
            case 'assignTo':
              sort = 'assignedTo'
              break
            case 'tags':
              sort = 'tags'
              break
            case 'hashTag':
              sort = 'hashtag'
              break
            default:
              break
          }
        }
        let getContact = []
        let getContactPb
        try {
          if (this.filterByUser === '') {
            try {
              const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}contacts/fetch-contacts`
              const payload = {
                subId: this.user.sub_id,
                sorting: sort,
                sign,
                page,
                itemsPerPage,
                search: this.filterByUser,
              }
              const { data: contacts } = await axios.post(endpoint, payload)
              getContactPb = contacts.data
            } catch (err) {
              console.log(err, 'err fetch contacts')
            }
          } else {
            try {
              const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}contacts/fetch-contacts`
              const payload = {
                subId: this.user.sub_id,
                sorting: sort,
                sign,
                page,
                itemsPerPage,
                search: this.filterByUser,
              }
              const { data: contacts } = await axios.post(endpoint, payload)
              getContactPb = contacts.data
            } catch (err) {
              console.log(err, 'err filter fetch contacts')
            }
          }
        } catch (err) {
          console.log(err, 'cuy<')
        }
        if (getContactPb) {
          getContact = getContactPb
          this.totalContacts = getContactPb.totalItems
        }
        this.contacts = getContactPb.finalData || []
        this.selectedAction = ''
        this.tableLoading = false
      } catch (error) {
        console.log(error, error.data, 'cok<')
      }
    },
    dataContact() {
      // let finalData = this.contactListTable

      // if (this.selectFilter) {
      //   if (this.selectFilter == 'assigned') {
      //     finalData = this.contactListTable.filter(
      //       contact =>
      //         contact.assign_to != null && contact.assign_to != false && contact.assign_to.email == this.user.email,
      //     )
      //   } else if (this.selectFilter == 'unreplied') {
      //     finalData = this.contactListTable.filter(contact => contact.unreplied == true)
      //   } else if (this.selectFilter == 'unread') {
      //     finalData = this.contactListTable.filter(contact => contact.unread_count > 0)
      //   }
      // }

      // if (this.selectedChannel !== 'all') {
      //   finalData = finalData.filter(contact => contact.roomOwnerId === this.selectedChannel)
      // }

      // const filterData = finalData.filter(x => {
      //   // //console.log('🚀 ~ file: contactData.vue ~ line 1540 ~ dataContact ~ x', x)
      //   let isFiltered = false
      //   if (this.filter !== '') {
      //     for (const field in x) {
      //       if (x[field] && x[field].toString().toLowerCase().includes(this.filter.toLowerCase())) {
      //         isFiltered = true
      //       }
      //       if ((field === 'last_reply' || field === 'last_message') && x[field] && !isFiltered) {
      //         var dateString = this.$moment(x[field] * 1000).format('DD-MM-YYYY HH:mm:ss')
      //         isFiltered = dateString.toString().toLowerCase().includes(this.filter.toLowerCase())
      //       }
      //       if (field === 'unreplied' && !isFiltered) {
      //         var dateString = x[field] === undefined ? '' : x[field] ? 'Unreplied' : 'Replied'
      //         isFiltered = dateString.toString().toLowerCase().includes(this.filter.toLowerCase())
      //       }
      //       if (field === 'level_engagement' && !isFiltered) {
      //         var dateString = this.getEngagementName(x[field])
      //         isFiltered = dateString.toString().toLowerCase().includes(this.filter.toLowerCase())
      //       }
      //       if (field === 'assign_to' && !isFiltered) {
      //         const email = x[field] ? x[field].email : ''
      //         isFiltered = email.toString().toLowerCase().includes(this.filter.toLowerCase())
      //       }
      //     }
      //   }
      //   for (const instance of this.listInstances.value) {
      //     if (x.instance_id === instance._id || x.roomOwnerId === instance.phone_number) {
      //       isFiltered = true
      //       if (this.selectedChannel === 'all') {
      //         instance.label =
      //           instance.label.startsWith('-') || instance.label.includes('Default') || instance.label.includes('62')
      //             ? instance.label_server
      //             : instance.label

      //         this.filterChannels.push(instance)
      //       }
      //     }
      //   }

      return finalData

      // })

      // //console.log(filterData, `INI FILTER DATA`)

      // return _uniqBy(filterData, 'phone_number')
    },
    closeDialogAssignContact() {
      this.dialogAssign = false
      this.chosenUser = ''
    },
    async saveAssignContact() {
      const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}contacts/batch-assign`
      const payload = {
        sub_id: this.user.sub_id,
        assign_to: this.chosenUser,
        ids: this.selectedTableData.map(ele => ele.id),
      }
      try {
        const test = await axios.post(endpoint, payload)
        await this.fetchContacts()
      } catch (error) {
        console.log(error, 'woee')
      }
      this.dialogAssign = false
      this.chosenUser = ''
      this.selectedAction = ''
      this.selectedTableData = []
    },
    remove(item) {
      const filterPhone = this.model.phoneNumber.indexOf(item)
      if (filterPhone !== -1) {
        this.model.phoneNumber.splice(filterPhone, 1)
      }

      const filterEmail = this.model.email.indexOf(item)
      if (filterEmail !== -1) {
        this.model.email.splice(filterEmail, 1)
      }

      const filterTag = this.model.tags.indexOf(item)
      if (filterTag !== -1) {
        this.model.tags.splice(filterTag, 1)
      }
    },
    getContactsNameRoom(phoneNumber, roomName) {
      if (this.couchAllContact) {
        const foundContact = this.couchAllContact.filter(x => x.phone_number === phoneNumber)

        if (foundContact.length > 0) {
          let name = `${foundContact[0].name} (`
          for (const contact of foundContact) {
            name += `${contact.list_name}, `
          }
          name = name.substring(0, name.length - 2)
          name += ')'

          if (name.endsWith('()')) {
            name = name.substring(0, name.length - 2)
          }

          return name
        }
      }

      if (this.contactListTableAll) {
        const foundContact = this.contactListTableAll.find(x => x.phone_number === phoneNumber)

        if (foundContact) {
          return foundContact.name
        }
      }

      return roomName || this.formatPhoneNumber(phoneNumber)
    },
    getLabelName(id) {
      const obj = this.labelData.find(x => x.id === id)
      if (obj) return obj.label

      return ''
    },
    getListLabel(contact) {
      const listLabel = []
      const filtered = this.labelValue.filter(x => x.contact && x.contact.phone_number === contact.phone_number)
      for (var data of filtered) {
        const label = this.labelData.find(x => x.id == data.label.id)
        if (label) {
          listLabel.push(label)
        } else {
          listLabel.push(data.label)
        }
      }

      return listLabel
    },

    // async setOperator(operator, contactData) {
    //   // //console.log(operator, contactData, `INI KONTAK DATA`)

    //   contactData.assign_to = operator
    //   // await this.fdbUpdateChatData(contactData)
    // },
    getStatisticChart(date) {
      if (date.length === 2) {
        if (new Date(date[0]).getTime() > new Date(date[1]).getTime()) {
          this.rangeDates = this.getDateRange(new Date(date[1]), new Date(date[0]))
        } else {
          this.rangeDates = this.getDateRange(new Date(date[0]), new Date(date[1]))
        }
      } else {
        const startDate = `${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 - 72000) * 1000).getDate()}`
        const endDate = `${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getFullYear()}-${
          new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(date[0]).getTime() / 1000 + 72000) * 1000).getDate()}`

        this.rangeDates = this.getDateRange(new Date(startDate), new Date(endDate))
      }
      const chart = {
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: '"Inter", sans-serif',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: 'straight',
        },
        colors: [
          // $themeColors.warning,
          // $themeColors.error,
          // $themeColors.secondary,
          // $themeColors.info,
          // $themeColors.success,

          '#ab7efd',
          '#F0D9FF',
          '#e0cffe',
          '#BFA2DB',
          '#b992fe',
          '#b391ec',
          '#b9a3dc',
          '#bdb5ca',
          '#bec6b9',
          '#8c62dd',
          '#966be8',
          '#a175f2',
          '#b588ff',
          '#c091ff',
          '#ca9bff',
          '#b089f3',
          '#b594e8',
          '#b89fde',
          '#bba9d4',
          '#bdb4c9',
          '#bebebe',
          '#bb93fe',
          '#caa8ff',
          '#d9beff',
          '#e6d3ff',
          '#f3e9ff',
          '#ffffff',
          '#8d68ce',
          '#6f53a1',
          '#533f76',
          '#392c4e',
        ],
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        fill: {
          opacity: 1,
          type: ['solid', 'gradient'],
        },
        xaxis: {
          categories: [],
        },
      }
      this.userDataCreated[0] = {
        data: [],
        name: 'Data Inserted',
      }

      //console.log(this.rangeDates, '<<<<<<<<<aaaaaaaaaaaaaaaaaaaaaaaaa')
      for (let i = 0; i < this.rangeDates.length; i++) {
        this.userDataCreated[0].data.push(0)
        this.dataContact.forEach(el => {
          if (this.rangeDates[i] === new Date(el.created_at).toISOString().substring(0, 10)) {
            this.userDataCreated[0].data[i] += 1
          }
        })
      }
      const range = JSON.parse(JSON.stringify(this.rangeDates))
      this.chartCreatedAt = chart

      this.chartCreatedAt.xaxis.categories = range
      this.menu = false
      //console.log(this.userDataCreated)
    },
    getDateRange(startDate, endDate) {
      const dates = []
      let currentDate = startDate
      const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)

        return date
      }
      while (currentDate <= endDate) {
        dates.push(new Date(currentDate).toISOString().substring(0, 10))
        currentDate = addDays.call(currentDate, 1)
      }

      // //console.log(dates, 'oooooooooooo')
      return dates
    },
    async loadTableCon() {
      const obj = (await this.loadContactsAll()).contacts.filter(el => el._id !== el.phone_number)
      const listContactObj = this.listContactValue.map(el => {
        //console.log(el.id)
        el.active_contact = obj.filter(elm => elm._id.substring(0, 8) == el.id).length

        return el
      })

      // this.listContactValue = [...listContactObj]
      this.totalContact = obj.totalContact
    },
    async loadSettingCon(isExist = false) {
      if (!isExist) {
        await this.loadSettings()
      }
      const res = await this.loadSettingContactsList(this.allSettings)
      this.labelData = res.labelData
      this.contactListSetting = res.contactListSetting
      this.listContactValue = res.listContactValue
      this.deleteUnusedContact(this.contactListTable, this.listContactValue)
    },
    urlify(message) {
      if (!message) return ''

      const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g

      message = message.replace(urlRegex, function (url) {
        let hyperlink = url

        if (!hyperlink.match('^https?://')) {
          hyperlink = `http://${hyperlink}`
        }

        if (url.includes('wa.send-cheap.com')) {
          return `<a href="${hyperlink}" target="_blank">Attachment</a>`
        }

        return `<a href="${hyperlink}" target="_blank">Attachment</a>`
      })

      return message

      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    },
    selectionSort(data) {
      const filterArray = (arr, idx1, idx2) => {
        ;[arr[idx1], arr[idx2]] = [arr[idx2], arr[idx1]]
      }

      for (let i = 0; i < data.length; i += 1) {
        let lowers = i

        for (let j = i + 1; j < data.length; j += 1) {
          if (data[lowers].order_number > data[j].order_number) {
            lowers = j
          }
        }
        if (i !== lowers) filterArray(data, i, lowers)
      }

      return data
    },

    async loadBackground() {
      // await this.connectSettings()
      await this.loadSetting()

      // await this.connectContacts(false)
      await this.loadTable()
    },

    // contact list
    maxTextLength(text) {
      if (text?.length < 10) {
        return text
      }
      let tmp = ''
      for (let i = 0; i < 9; i++) {
        tmp += text[i]
      }
      tmp += '...'

      return tmp
    },
    deleteColumn(item, index) {
      this.listDraggable.splice(index, 1)
    },
    openNotes(note) {
      this.notes = note
      this.dialogNotes = true
    },

    closeDialog(fromDialog = true) {
      // //console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
      this.addBroadcastDialog = false

      this.selectedAction = 0

      // //console.log(this.selectedAction)

      if (fromDialog) {
        this.$router.push({
          name: 'broadcast-list',
        })
      }
    },
    importIsCancelled(cancel = true) {
      this.isCancelled = cancel
      // console.log(this.isCancelled, '<is cancel')
    },
    importContact() {
      let tempPhoneDuplicate = {}
      this.selected = {}
      this.$refs.dialogImport.open(this.labelData, [], this.idlist, this.totalContact).then(async confirm => {
        if (confirm.status) {
          this.tableLoading = true
          /// not sure if faster
          const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}contacts/import`
          const payload = {
            sub_id: this.user.sub_id,
            selected: this.selected,
            confirm: {
              data: confirm.data,
            },
          }
          try {
            const test = await axios.post(endpoint, payload)
            this.importContactInfo = {
              status: true,
              data: test.data.data,
            }
          } catch (error) {
            console.log(error, 'woee')
          }

          // this.importContactInfo = {
          //   status: true,
          //   data: `${
          //     dataQueryContacts.length > 1
          //       ? dataQueryContacts.length + ' contacts are'
          //       : dataQueryContacts.length + ' contact is'
          //   } added, ${
          //     duplicatePhone.length > 1
          //       ? duplicatePhone.length + ' contacts already exist'
          //       : duplicatePhone.length + ' contact already exists'
          //   }`,
          // }

          try {
            await this.fetchContacts()
          } catch (err) {
            console.log(err, 'cuyyy')
          }
          // this.selected.tags = []
          this.selectedTags = []
          this.tableLoading = false
        } else {
          // this.selected.tags = []
          this.selectedTags = []
        }
      })
    },

    exportTable() {
      // naive encoding to csv format
      //console.log(this.dataContact, 'data contact')
      const self = this
      const content = [this.columnsExport.map(col => wrapCsvValue(col.text))]
        .concat(
          this.dataContact.map(row =>
            this.columnsExport
              .map(col => {
                if (col.field === 'last_interaction') {
                  if (row[col.field]) {
                    return row[col.field].phone_number
                      ? row[col.field].phone_number.substr(0, row[col.field].phone_number.indexOf('@'))
                      : ''
                  }

                  return ''
                }
                if (col.field === 'last_message' || col.field === 'last_reply') {
                  if (row[col.field]) {
                    return self.$moment(row[col.field] * 1000).format('DD-MM-YYYY HH:mm:ss')
                  }

                  return ''
                }

                return wrapCsvValue(
                  typeof col.field === 'function' ? col.field(row) : row[col.field === void 0 ? col.text : col.field],
                  col.format,
                )
              })
              .join(','),
          ),
        )
        .join('\r\n')

      const anchor = document.createElement('a')
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`
      anchor.target = '_blank'
      anchor.download = 'contact-list.csv'
      anchor.click()
    },
    async selectAction(e, data) {
      console.log(e, data, '<<masukk nyund')
      this.selectedAction = e
      const self = this
      if (this.selectedAction === 'delete') {
        this.$refs.confirm
          .open(
            this.$t('delete'),
            `${this.$t('Delete Contact')} ${data.user.name}
            ?`,
            { color: 'red' },
            this.$t('delete'),
          )
          .then(async agree => {
            if (agree) {
              this.tableLoading = true

              if (data.allTagId.length !== 0) {
                for (let i = 0; i < data.allTagId.length; i++) {
                  const tagId = data.allTagId[i]
                  const idTag = this.$Md5Hash(`${tagId}-${data.id}`)
                  try {
                    await clientPocketBase.collection('crm_contact_tags').delete(idTag, { $autoCancel: false })
                  } catch (err) {}
                }
              }

              const allContact = await clientPocketBase.collection('crm_contact').getFullList(200, {
                filter: `contacts = '${data.id}'`,
                $autoCancel: false,
              })
              // console.log(data.id, allContact, '<<vira delete conatct')
              if (allContact.length) {
                for (let i = 0; i < allContact.length; i++) {
                  const id = allContact[i].id
                  await clientPocketBase
                    .collection('crm_contact')
                    .update(id, { contacts: null }, { $autoCancel: false })
                }
              }

              const response = await clientPocketBase.collection('crm_form_response').getFullList(200, {
                filter: `contacts = '${data.id}'`,
                $autoCancel: false,
              })

              if (response.length !== 0) {
                for (let i = 0; i < response.length; i++) {
                  const id = response[i].id
                  await clientPocketBase.collection('crm_form_response').delete(id, { $autoCancel: false })
                }
              }

              let formatPhone = []
              formatPhone.push(data.user.phoneNumber)
              if (formatPhone.length) {
                for (let i = 0; i < formatPhone.length; i++) {
                  const phone = formatPhone[i]
                  const phoneId = this.$Md5Hash(`${this.user.sub_id}-${phone}`)
                  await clientPocketBase.collection('crm_contact_phones').delete(phoneId, { $autoCancel: false })
                }
              }
              await clientPocketBase.collection('crm_contacts').delete(data.id, { $autoCancel: false })
              formatPhone = []
              this.fetchContacts()
              this.tableLoading = false
            }
          })
      } else if (this.selectedAction === 'edit') {
        this.$refs.dialogBatchEdit.open(data)
      } else if (this.selectedAction === 'label') {
        this.$refs.selectLabel.open(this.selectedTableData, this.labelData, this.labelValue).then(async res => {
          if (res && res.status) {
            this.selectedTableData = []
          }
          this.selectedAction = '-'
          setTimeout(() => {
            this.selectedAction = 0
            //console.log(this.selectedAction)
          }, 100)
        })
      } else if (this.selectedAction === 'assign') {
        this.$refs.dialogSelectOperator.open(this.userList).then(async result => {
          if (result.status && result.data) {
            self.loading = true
            for (const data of this.selectedTableData) {
              data.assign_to = {
                email: result.data.value ? result.data.value.email : result.data.email ? result.data.email : '',
                image: '',
                name: '',
                phone_number: '',
                prof_pic: '',
              }

              await this.updateRoomCouchDb(this.user.subId, data.instance_id, data.roomId, {
                assign_to: data.assign_to,
              })
            }

            // await self.bulkUpdateContact(self.selectedTableData, async success => {
            //   if (success) {
            //     this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, async info => {
            //       await this.loadTable()
            //     })
            //   }
            //   self.loading = false
            // })
            await this.loadTable()
            self.loading = false
            this.selectedTableData = []
          }
          this.selectedAction = 0
        })
        this.selectedAction = 0
      } else if (this.selectedAction === 'deleteContact') {
        this.loading = true
        // console.log(this.selectedTableData, '<<viraaaa')
        for (let i = 0; i < this.selectedTableData.length; i++) {
          const contact = this.selectedTableData[i]
          await clientPocketBase
            .collection('crm_contacts')
            .delete(this.$Md5Hash(`${this.user.sub_id}-${contact.phone.phone_number[0]}`), { $autoCancel: false })
        }
        await this.fetchContacts()
        this.loading = false
        this.selectedTableData = []
      } else if (this.selectedAction === 'send') {
        this.addBroadcastDialog = true
        this.newBroadcast += 1
      } else if (this.selectedAction === 'add') {
        this.$refs.dialogSelectList.open('', this.listContactValue).then(async result => {
          if (result.status && result.data) {
            await this.addContactToList(this.selectedTableData, result.data, this.listContactValue, async status => {
              // if (status === false) {
              //   this.showAlert(this.$t('warningfailedsavedata'))
              // } else {
              //   this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, async info => {
              //     await this.loadTable()
              //   })
              // }
              await this.loadTable()
              this.loading = false
            })
            this.selectedTableData = []
          } else {
            this.selectedAction = 0
          }
        })
      } else if (this.selectedAction === 'tags') {
        this.$refs.dialogSetTags.open('tags', data.id, data)
      } else if (this.selectedAction === 'detail') {
        // this.$emit('contact-info')
        // await this.$router.push({ name: 'contactDetail', params: { id: data.user.id } })
        // location.reload()
        const url = '/contacts/detail/' + data.user.id
        // window.open(url, "_blank");
        window.location.href = url
      }
      this.selectedAction = 0
    },
    async selectActions() {
      if (!this.selectedTableData || !this.selectedTableData.length) {
        //console.log(this.selectedAction, 'ini sb')
        this.selectedAction = ''
        //console.log(this.selectedAction, 'ini sbtlh')
        return
      }
      console.log(this.selectedAction, 'ini sb')
      const pronoun = this.selectedTableData.length > 1 ? 'Contacts' : 'Contact'
      if (this.selectedAction === 'delete') {
        this.$refs.confirm
          .open(
            this.$t('delete'),
            `Delete ${this.selectedTableData.length} ${pronoun}
            ?`,
            { color: 'red' },
            this.$t('delete'),
          )
          .then(async agree => {
            if (agree) {
              this.tableLoading = true
              let formatPhone = []
              for (let i = 0; i < this.selectedTableData.length; i++) {
                const selected = this.selectedTableData[i]
                if (selected.allTagId.length) {
                  for (let i = 0; i < selected.allTagId.length; i++) {
                    const tagId = selected.allTagId[i]
                    const idTag = this.$Md5Hash(`${tagId}-${selected.id}`)
                    // //console.log(idTag, 'ini idtek')
                    try {
                      await clientPocketBase.collection('crm_contact_tags').delete(idTag, { $autoCancel: false })
                    } catch (err) {
                      console.log(err, 'err1')
                    }
                  }
                }
                try {
                  const response = await clientPocketBase.collection('crm_form_response').getFullList(200, {
                    filter: `contacts = '${selected.id}'`,
                    $autoCancel: false,
                  })

                  if (response.length !== 0) {
                    for (let i = 0; i < response.length; i++) {
                      const id = response[i].id
                      await clientPocketBase.collection('crm_form_response').delete(id, { $autoCancel: false })
                    }
                  }
                } catch (err) {
                  console.log(err, 'err2')
                }

                try {
                  const phoneLengthFirstIndex = formatPhone.length
                  await clientPocketBase.collection('crm_contacts').delete(selected.id, { $autoCancel: false })
                  formatPhone.push(selected.user.phoneNumber)
                  const phoneNum = formatPhone[phoneLengthFirstIndex].split(',').map(x => x.trim())
                  for (let i = 0; i < phoneNum.length; i++) {
                    const phone = phoneNum[i]
                    const phoneId = this.$Md5Hash(`${this.user.sub_id}-${phone}`)
                    await clientPocketBase.collection('crm_contact_phones').delete(phoneId, { $autoCancel: false })
                  }
                  formatPhone = []
                } catch (err) {
                  console.log(err, 'err3')
                }
              }

              this.fetchContacts()
              this.selectedAction = ''
              this.tableLoading = false
            }
          })
          .catch(err => {
            console.log(err, 'err disconne')
          })
      } else if (this.selectedAction === 'tags') {
        const dataIds = this.selectedTableData.map(ele => ele.id)
        this.$refs.dialogSetTags.open('bulk_tags', this.selectedTableData)
        this.selectedTableData = []
        ///masih buggy ini untuk batch Set Labels
      } else if (this.selectedAction === 'addForm') {
        let temp = { id: [], name: '' }
        for (let i = 0; i < this.selectedTableData.length; i++) {
          const data = this.selectedTableData[i]
          temp.id.push(data.user)
          temp.name = data.user.name
        }
        this.contactIdResponse.id = temp.id
        this.contactIdResponse.name = temp.name

        this.$refs.dialogAddSurvey.open()
        this.selectedAction = ''
        this.selectedTableData = []
      } else if (this.selectedAction === 'grouping') {
        this.$refs.dialogBatchEdit.open(this.selectedTableData, 'grouping')

        this.fetchContacts()
        this.selectedAction = ''
        this.selectedTableData = []
      } else if (this.selectedAction === 'assign') {
        this.dialogAssign = true
        this.fetchContacts()
        this.selectedAction = ''
        this.selectedTableData = []
      }
    },
    async toFormResponse(item) {
      await this.$router.push({
        name: 'formDetail',
        params: { formId: item.id },
        query: { contactId: JSON.stringify(this.contactIdResponse.id) },
      })
      location.reload()
    },
    openSelectTags(data) {
      if (data.id) {
        this.$refs.dialogSetTags.open('edit', data.id, data)
      } else {
        this.$refs.dialogSetTags.open('add', data)
      }
    },
    async saveContact() {
      if (this.model.contactName === '' || this.model.phoneNumber === '' || this.model.phoneNumber.length === 0) {
        this.errorTitleValidationSnackbar = true
        this.errorMessages = ['This field is required']
        this.errorMessagesPhone = ['This field is required']
      } else {
        const phoneRegex = /^\d+$/
        const allNumbersValid = this.model.phoneNumber.every(phone => {
          return phoneRegex.test(phone)
        })
        if (!allNumbersValid) {
          this.errorTitleValidationSnackbar = true
        }
      }
      if (!this.errorTitleValidationSnackbar) {
        let duplicateAlert = []
        let isExist
        // const allPhoneNumber = await this.fetchAllPhoneNumber
        const nanoId = this.$nanoid()
        const newContact = await clientPocketBase.collection('crm_contacts').create(
          {
            id: this.$Md5Hash(nanoId + '-' + this.user.sub_id),
            name: this.model.contactName,
            avatar: this.photoLinks,
            nickname: this.model.nickname,
            email: { email: this.model.email },
            subId: this.user.sub_id,
            tags: this.selected,
            platform: 'wa',
          },
          { $autoCancel: false },
        )

        let allPhone = []
        for (let j = 0; j < this.model.phoneNumber.length; j++) {
          const inputPhone = this.model.phoneNumber[j]
          const formatInputPhone = inputPhone.split(' ').join('').replace(/^0+/, '62')

          if (!isNaN(formatInputPhone)) {
            try {
              const getPhoneContacts = await clientPocketBase
                .collection('crm_contact_phones')
                .getFirstListItem(`phone_number = '${formatInputPhone}'`, {
                  expand: 'contact_id',
                  $autoCancel: false,
                })

              if (getPhoneContacts) {
                duplicateAlert.push(formatInputPhone)
                this.errorPhoneValidationSnackbar = {
                  status: true,
                  phone: duplicateAlert.toString().split(',').join(', '),
                }
                isExist = true
              }
            } catch (err) {
              if (err.status == 404) {
                isExist = false
              } else {
                console.log(err)
              }
            }
          }

          if (!isExist) {
            this.loading = true
            const newPhoneNumber = await clientPocketBase.collection('crm_contact_phones').create(
              {
                id: this.$Md5Hash(`${this.user.sub_id}-${formatInputPhone}`),
                phone_number: formatInputPhone,
                contact_id: newContact.id,
                sub_id: this.user.sub_id,
              },
              { $autoCancel: false },
            )
            allPhone.push(formatInputPhone)
            this.dialogContact = false
          } else {
            const deleteContact = await clientPocketBase
              .collection('crm_contacts')
              .delete(newContact.id, { $autoCancel: false })
          }
        }
        const updatePhoneContact = await clientPocketBase.collection('crm_contacts').update(
          newContact.id,
          {
            phoneNumber: allPhone,
          },
          { $autoCancel: false },
        )
        try {
          // const idx = this.contactListTable.findIndex(x => x.phone_number === newContact.phone)
          // if (idx > -1) this.contactListTable[idx] = newContact
          // else this.contactListTable.push(newContact)
          await this.fetchContacts()

          const selected = Object.keys(this.selected)
          if (selected !== undefined) {
            for (let i = 0; i < selected.length; i++) {
              const tag = selected[i]
              const id = this.$Md5Hash(`${tag}-${newContact.id}`)
              if (this.selected[selected[i]] !== null) {
                try {
                  const newContactTags = await clientPocketBase.collection('crm_contact_tags').create(
                    {
                      id,
                      tag: tag,
                      variant: this.selected[selected[i]].value,
                      contacts: newContact.id,
                      subId: this.user.sub_id,
                      rawData: Object.fromEntries(Object.entries(this.selected).filter(([_, v]) => v != null)),
                    },
                    { $autoCancel: false },
                  )
                } catch (err) {
                  console.log(err, 'error')
                }
              }
            }
          }
          this.loading = false
          this.$store.commit('inbox/setIsTagChanged', true)
        } catch (err) {
          // this.showAlert(res.errorMessage)
          this.dialogContact = true
          //console.log('🚀 ~ file: Chat.vue ~ line 1421 ~ assignChat ~ err', err)
        }
      }
    },
    openNewContact() {
      this.model = {
        contactName: '',
        phoneNumber: '',
        created_at: Date.now(),
        data: [],
        notes: [],
      }
      this.photoLinks = ''
      this.valueRequired = ''
      this.valueRequiredPhone = ''
      this.selectedTags = []
      this.errorMessages = []
      this.errorMessagesPhone = []
      this.requiredRules = [true]
      this.validators.required = true
      this.dialogContact = true
    },
    openEditContact(data) {
      this.isEdit = true
      this.model = data
      this.model.contactName = data.name
      this.model.phoneNumber = data.phone_number
      this.model.data = []
      this.columnsAdditional.forEach((x, index) => {
        this.model.data.push({
          index,
          attributeLabel: x.label,
          attributeSelected: x.text,
          attributeValue: data[x.value],
        })
      })
      this.dialogContact = true
    },
    async deleteContact(data) {
      this.$refs.confirm
        .open(
          this.idlist
            ? this.$t('delete')
            : this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed'
            ? this.$t('unarchived')
            : this.$t('archive'),
          `${
            this.idlist
              ? this.$t('deleteConfirmation')
              : this.selectFilter && (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
              ? this.$t('unarchivedConfirmation')
              : this.$t('archivedConfirmation')
          }  ${data.name} ?`,
          { color: 'red' },
          this.idlist
            ? this.$t('delete')
            : this.selectFilter && (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
            ? this.$t('unarchived')
            : this.$t('archive'),
        )
        .then(async confirm => {
          if (confirm) {
            this.loading = true
            if (this.idlist) {
              data._deleted = true
            } else if (
              this.selectFilter &&
              (this.selectFilter.name === 'Archived' || this.selectFilter.name === 'Closed')
            ) {
              data.archived = 0
            } else {
              data.archived = 1
            }

            if (data.lastMessage && data.message_container.length > 0) {
              const endpoint = `${process.env.VUE_APP_API_GENERAL}messages/archived-chat`
              const body = {
                subId: data.subId,
                roomId: data.roomId,
                last_message: data.message_container[data.message_container.length - 1].original_message,
                remote_jid: data.message_container[data.message_container.length - 1].original_message.key.remoteJid,
                archived: data.archived,
                server: 'wabailey',
              }

              this.$axios
                .post(endpoint, body)
                .then(response => {
                  //console.log(response, ' == response api general')
                })
                .catch(error => {
                  console.log(error)
                })
            }

            // await this.fdbUpdateChatData(data)
            // await this.loadTable()
            await this.updateContact(data, async status => {
              if (status) {
                this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, async info => {
                  await this.loadTable()
                })
                this.selectedAction = 0
              } else {
                this.showAlert(this.$t('warningfailedsavedata'))
              }
              this.loading = false
            })
          }
        })
    },
    showAlert(warningText) {
      this.alertText = warningText
      this.dialogAlert = true
    },
    async saveConfig() {
      this.dialogConfig = false
      this.loading = true
      const res = await this.saveConfigToDb(this.listDraggable, this.tableColumns)
      if (res.error) {
        this.dialogConfig = true
        this.showAlert(res.errorMessage)
      } else {
        await this.loadSetting()
        this.tableColumns = res.data
        this.dialogConfig = false
      }
      this.loading = false
    },
    async saveAttribute(data) {
      this.columnsSetting.value = data

      await this.updateSettings(this.columnsSetting)
    },
    addNewColumn() {
      this.listDraggable.push({
        name: '',
        align: 'center',
        label: '',
        field: '',
        value: '',
        text: '',
        sortable: true,
        type: 'text',
        show: true,
        showable: true,
        formatDate: '',
        deleteable: true,
        isRequired: false,
        defaultValue: '',
        editable: true,
        order_number: this.tableColumns.length + 1,
      })
    },
    getMessageStatusResult(status) {
      if (!status || status === 0) {
        return 'Unsend'
      }
      if (status === 1) {
        return 'Sent'
      }
      if (status === 2) {
        return 'Received'
      }
      if (status === 3) {
        return 'Read'
      }

      return 'Replied'
    },
    getEngagementName(level) {
      if (!level) return 'No Interaction'
      if (level === 0) {
        return 'No Interaction'
      }
      if (level === 1) {
        return 'Very Low'
      }
      if (level === 2) {
        return 'Low'
      }
      if (level === 3) {
        return 'Medium'
      }
      if (level === 4) {
        return 'High'
      }

      return 'Very High'
    },
    getProfPic(img, item) {
      if (img) return img
    },
    dateFormater(dt) {
      return TimeFormater.dateFormater(dt)
    },
    gotoInbox(data) {
      //console.log('data: ', data)

      // //console.log(data)
      // data.roomId = data._id

      this.$router.push({
        name: 'inbox-phone-number',
        params: {
          phoneNumber: data.roomId,
        },
      })
    },
    openConfig() {
      this.$refs.dialogProfileConfig.open()
    },
    async loadTable() {
      // const obj = await this.loadContactsIdList(this.idlist)

      // await this.fdbGetAllContactFromRoom(this.user.sub_id)

      // const itemRoom =

      try {
        //   this.couchAllContact = await this.loadContentContactsList()
        // } catch (err) {
        //   console.log(err)
        //   this.couchAllContact = []
        // }
        // this.contactListTableAll = await this.loadContacts(true)
        const query = {
          token: this.user.token,
          sub_id: this.user.sub_id,
        }

        // const roomsFromPg = await this.$store.dispatch('rooms/getRoomsContactDataPostgres', query)
        // //console.log(roomsFromPg.data.data, '<< vira contact')
        // if (roomsFromPg.status) {
        //   this.contactListTable = roomsFromPg.data.data
        // }
        try {
          // const rooms = await clientPocketBase.collection('crm_contacts').getFullList(200)
          // this.contactListTable = rooms
          // this.listContactValue = rooms
        } catch (err) {
          //console.log('🚀 ~ file: Chat.vue ~ line 1421 ~ assignChat ~ err', err)
        }

        const res = await this.$store.dispatch('global/getContactLists', {
          sub_id: this.user.sub_id,
          token: this.user.token,
        })
        if (res.status) {
          this.listContactValue = res.data
        }

        // this.contactListTable = this.contactListTableAll.filter(x => x.lastMessage)

        // obj.contacts.forEach(el => {
        //   if (el.hasOwnProperty('archived')) {
        //
      } catch (err) {
        console.log(err)
      }

      // })
    },
    async loadSetting(isExist = false) {
      const columnsPg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'columns',
      })

      if (columnsPg.status) {
        let filteredObjColumn = columnsPg.data.value
        filteredObjColumn = this.selectionSort(filteredObjColumn)
        this.tableColumns = filteredObjColumn
      }

      // if (!isExist) {
      //   await this.loadSettings()
      // }
      // const obj = await this.mappingSettingsContacts(this.idlist, this.allSettings)

      // let filteredObjColumn = obj.tableColumns
      // filteredObjColumn = this.selectionSort(filteredObjColumn)

      // this.columnsSetting = obj.columnsSetting
      // this.tableColumns = filteredObjColumn
      this.itemsBreadcrumbs = [
        {
          text: 'Contact',
          disabled: false,
          exact: true,
          link: true,
          to: {
            name: 'contacts-group-list',
          },
        },
      ]

      const labelsPg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'label',
      })

      if (labelsPg.status) {
        this.labelData = labelsPg.data.value
      }
      const labelsValuePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'label_value',
      })

      if (labelsValuePg.status) {
        this.labelValue = labelsValuePg.data.value
      }

      // this.labelData = obj.labelData
      // this.labelSetting = obj.labelSetting
      // this.labelValue = obj.labelValue
      // this.settingFilterSetting = obj.settingFilterSetting
    },
  },
}
</script>

<style scoped>
.heigth-dialog {
  max-height: 400px;
  overflow-y: auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
@media only screen and (max-width: 578px) {
  .sm-display {
    display: none;
  }

  .last-served-image {
    display: none;
  }

  .last-served {
    font-size: 12px;
  }

  .phone-number {
    font-size: 12px;
  }
}

@media only screen and (min-width: 578px) {
  .sm-display-mobile {
    display: none;
  }
}
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
.padding-hero {
  padding-left: 80px;
  padding-right: 80px;
}
.mobile-tag {
  display: none;
}
/* Mobile */

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
    width: 200px;
  } */
}

.img-avatar {
  width: 141.89px;
  height: 130px;
  margin-left: 109.15px;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-radius: 20px;
}
.colorline-chat {
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.vac-avatar-frame {
  height: 51px;
  width: 52px;
  min-height: 53px;
  min-width: 52px;
  margin-right: 15px;
  margin-left: 5%;
  border-radius: 50%;
  margin-bottom: -1px;
}

.name-contact {
  color: #111b21;
  font-weight: 400;
  font-size: 16px;
}
</style>
