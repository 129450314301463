/* eslint-disable guard-for-in */
<template>
  <v-card>
    <!-- dialog alert -->
    <v-dialog v-model="dialogAlert" width="500" @keydown.esc="dialogAlert = false">
      <v-card>
        <v-card-title color="error">
          {{ $t('warning') }}
        </v-card-title>

        <v-card-text>
          {{ alertText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogAlert = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogImport" width="800px" @keydown.esc="cancel">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('Import') }}
          </h3>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3" @click="cancel">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" @click="agree">
            {{ $t('preview') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-expansion-panels v-model="tab" focusable :disabled="loadingDialog">
          <v-expansion-panel v-for="(item, i) in listNameImport" :key="i + '-import'">
            <v-expansion-panel-header> {{ `${$t('from')} ${item}` }}</v-expansion-panel-header>
            <v-expansion-panel-content v-if="i === 0">
              <v-file-input
                v-model="importFileCsv"
                :disable="isImportCsvOngoing"
                outlined
                :label="$t('contacts.chooseCsv')"
                accept=".csv"
              />
              <!-- <iframe
                style="position: relative; height: 50vh; width: 100%; border-style:none;"
                :src="getImportUrl()"
              /> -->
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="i === 1">
              <v-file-input
                v-model="importFileVcf"
                :disable="isImportVcfOngoing"
                outlined
                :label="$t('contacts.chooseVcf')"
                accept=".vcf"
              />
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="i === 2">
              <v-row :justify="'center'" class="pa-0 ma-0">
                <v-col md="12" class="pa-0 ma-0">
                  <!-- <pre>{{listInstance}}</pre> -->
                  <v-select
                    v-model="selectedInstance"
                    :items="listInstance.filter(ele => !ele.broadcast_group)"
                    item-text="label"
                    item-value="_id"
                    dense
                    :label="`${$t('select')} ${$t('Channels')}`"
                    outlined
                    filled
                  >
                    <template #label>
                      <v-progress-circular
                        v-if="contactChannelLoading"
                        indeterminate
                        width="2"
                        size="18"
                        color="primary"
                      ></v-progress-circular>
                      <span class="ml-1"> {{ `${$t('select')} ${$t('Channels')}` }} </span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <!-- <v-row
                :justify="'center'"
                class="pa-0 ma-0"
              >
                <v-col
                  md="auto"
                  class="pa-0 ma-0"
                >
                  {{ $t('or') }} {{ $t('scan') }}
                </v-col>
              </v-row>
              <v-row
                :justify="'center'"
                class="pa-0 ma-0"
              >
                <v-col
                  md="auto"
                  class="pa-0 ma-0"
                >
                  <iframe
                    id="iframe-qr-code"
                    title="QR Code"
                    width="276"
                    height="277"
                    style="border: 0"
                    loading="lazy"
                    :src="iframeSrc"
                  >
                  </iframe>
                </v-col>
              </v-row> -->
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="i === 3">
              <v-row :justify="'center'" class="pa-0 ma-0">
                <v-col md="12" class="pa-0 ma-0">
                  <v-autocomplete
                    v-model="selectedInstance"
                    :items="listInstance.filter(ele => !ele.broadcast_group)"
                    item-text="label"
                    item-value="_id"
                    dense
                    label="Select Channel"
                    outlined
                    filled
                    @change="fetchGroup"
                  >
                    <template #label>
                      <v-progress-circular
                        v-if="contactGroupLoading"
                        indeterminate
                        width="2"
                        size="18"
                        color="primary"
                      ></v-progress-circular>
                      <span class="ml-1"> Select Channel </span>
                    </template>
                    <!-- <template #selection="data">
                      <span class="mr-2"> {{ data.label || `${$t('select')} ${$t('contactGroup')}` }} </span>
                      <v-chip v-if="data.data_chanel" small color="primary">
                        {{ `${data.data_chanel}` }}
                      </v-chip>
                    </template>
                    <template #item="data">
                      <span class="mr-2"> {{ data.label || `${$t('select')} ${$t('contactGroup')}` }} </span>
                      <v-chip v-if="data.data_chanel" small color="primary">
                        {{ `${data.data_chanel}` }}
                      </v-chip>
                    </template> -->
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row :justify="'center'" class="pa-0 ma-0">
                <v-col md="12" class="pa-0 ma-0">
                  <v-autocomplete
                    v-model="chosenGroup"
                    :items="listGroup"
                    item-text="name"
                    :disabled="selectedInstance ? false : true"
                    item-value="id"
                    return-object
                    dense
                    :label="`Select Group`"
                    outlined
                    filled
                  >
                    <template #label>
                      <v-progress-circular
                        v-if="contactGroupLoading"
                        indeterminate
                        width="2"
                        size="18"
                        color="primary"
                      ></v-progress-circular>
                      <span class="ml-1"> Select Group </span>
                    </template>
                    <!-- <template #selection="data">
                      <span class="mr-2"> {{ data.label || `${$t('select')} ${$t('contactGroup')}` }} </span>
                      <v-chip v-if="data.data_chanel" small color="primary">
                        {{ `${data.data_chanel}` }}
                      </v-chip>
                    </template>
                    <template #item="data">
                      <span class="mr-2"> {{ data.label || `${$t('select')} ${$t('contactGroup')}` }} </span>
                      <v-chip v-if="data.data_chanel" small color="primary">
                        {{ `${data.data_chanel}` }}
                      </v-chip>
                    </template> -->
                  </v-autocomplete>
                </v-col>
              </v-row>
              <!-- <v-row
                :justify="'center'"
                class="pa-0 ma-0"
              >
                <v-col
                  md="auto"
                  class="pa-0 ma-0"
                >
                  {{ $t('or') }} {{ $t('scan') }}
                </v-col>
              </v-row> -->
              <!-- <v-row
                :justify="'center'"
                class="pa-0 ma-0"
              >
                <v-col
                  md="auto"
                  class="pa-0 ma-0"
                >
                  <iframe
                    id="iframe-qr-code"
                    title="QR Code"
                    width="276"
                    height="277"
                    style="border: 0"
                    loading="lazy"
                    :src="iframeSrc"
                  >
                  </iframe>
                </v-col>
              </v-row> -->
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="i === 4">
              <v-text-field
                v-model="linkGsheet"
                outlined
                :label="`${$t('link')} Google Spreadsheet`"
                :hint="$t('contacts.hintSheet')"
              />
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="i === 5">
              <v-file-input
                v-model="importFileExcel"
                outlined
                type="file"
                :label="$t('contacts.chooseExcel')"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>
    <!-- dialog preview -->
    <v-dialog v-model="dialogImportPreview" persistent width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('Import') }}
          </h3>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3" @click="cancel">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn v-if="mappingData" color="success" @click="importContact">
            {{ $t('import') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-select
          v-model="selectedTags"
          :items="selectedTags"
          :menu-props="{ top: false, offsetY: true, display: 'none' }"
          dense
          chips
          deletable-chips
          label="Add Labels"
          multiple
          outlined
          no-data-text
          readonly
          class="m-8"
          @click="selectTags"
        ></v-select>
        <div v-if="tab !== 1 && tab !== 2 && tab !== 3 && !mappingData" class="px-2 mx-3 mt-3">
          <v-row class="pa-0 ma-0">
            <v-col dense cols="12" md="4" class="pa-0 ma-0">
              {{ $t('contacts.name') }}
            </v-col>
            <v-col dense cols="12" md="8" class="pa-0 ma-0">
              <v-select
                v-model="fieldName"
                :items="listFields"
                item-text="label"
                dense
                :label="`${$t('select')} ${$t('Fields')}`"
                outlined
                filled
              ></v-select>
            </v-col>
            <v-col dense cols="12" md="4" class="pa-0 ma-0">
              {{ $t('contacts.phoneNumber') }}
            </v-col>
            <v-col dense cols="12" md="8" class="pa-0 ma-0">
              <v-select
                v-model="fieldPhoneNumber"
                :items="listFields"
                item-text="label"
                dense
                :label="`${$t('select')} ${$t('Fields')}`"
                outlined
                filled
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-for="(column, index) of additionalColumn" :key="index" class="pa-0 ma-0">
            <v-col dense cols="12" md="4" class="pa-0 ma-0">
              {{ column.text }}
            </v-col>
            <v-col dense cols="12" md="8" class="pa-0 ma-0">
              <v-select
                v-model="fieldValue[index]"
                :items="listFields"
                item-text="label"
                dense
                :label="`${$t('select')} ${$t('Fields')}`"
                outlined
                filled
              ></v-select>
            </v-col>
          </v-row>
          <div class="pa-0 ma-0">
            <v-btn color="primary" class="mb-3 mr-3 ml-3" @click="mapping">
              {{ $t('submit') }}
            </v-btn>
            <v-btn color="primary" class="mb-3" @click="addNewColumn">
              {{ $t('new') + ' Column' }}
            </v-btn>
          </div>
        </div>
        <div v-if="mappingData" class="px-2 mx-3 mb-3">
          <v-row class="pa-0 ma-0">
            <v-col dense cols="12" md="12" class="pa-0 mt-3"></v-col>
          </v-row>
          <v-row class="pa-0 ma-0">
            <v-col class="pa-0 ma-0">
              <v-checkbox v-model="alldata" dense :label="$t('selectall')"></v-checkbox>
              {{ `${selectedTableData.length} ${$t('selectedOf')} ${dataImport.length} Contacts` }}
            </v-col>
          </v-row>
          <v-row>
            <v-col></v-col>
            <v-col class="pb-10">
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                label="Search"
                single-line
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <pre>{{dataImport[0]}}</pre> -->
          <v-data-table
            v-model="selectedTableData"
            disabled="true"
            :headers="headersImport"
            :items="dataImport"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            item-key="_uid"
            show-select
            :search="search"
            :items-per-page="10"
          >
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>

    <!-- SELECT TAGS -->
    <v-dialog v-model="openSelect" persistent width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('Select Labels') }}
          </h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <!-- <v-divider /> -->
        <div class="px-2 mx-3 mt-3">
          <v-card elevation="1" outlined height="373" width="523" class="mb-8 mx-auto py-5 px-8">
            <v-row v-for="(column, index) of tags" :key="index" class="pa-0 ma-0">
              <v-col dense cols="12" md="4" class="pa-0 ma-0">
                <v-checkbox
                  v-model="openSelect"
                  :label="column"
                  color="primary"
                  :value="column"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col dense cols="12" md="8" class="pa-0 ma-0">
                <v-select
                  v-model="fieldName"
                  :items="listFields"
                  item-text="label"
                  dense
                  :label="`${$t('Varian')}`"
                  outlined
                  filled
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
          <div class="mr-5 d-flex justify-end flex-row">
            <v-btn class="mb-8 pa-2" plain @click="closeTag">
              <span class="error--text">{{ $t('Cancel') }}</span>
            </v-btn>
            <span>
              <v-btn color="primary" elevation="2" outlined class="mb-8 mr-5 ml-5" @click="mapping">
                {{ $t('Add') }}
              </v-btn>
            </span>
            <v-btn color="primary" class="mb-8" @click="addNewColumn">
              {{ $t('Save') }}
            </v-btn>
          </div>
        </div>
        <!-- <v-snackbar
              v-model="errorPhoneValidationSnackbar.status"
              color="error"
              :top="true"
              style="z-index: 5 !important"
            >
              Your Phone Number {{ errorPhoneValidationSnackbar.phone }} Already Registered
              <template #action="{ attrs }">
                <v-btn text v-bind="attrs" @click="errorPhoneValidationSnackbar = false"> {{ $t('Close') }} </v-btn>
              </template>
            </v-snackbar> -->
      </v-card>
    </v-dialog>

    <!-- dialog loading -->
    <v-dialog v-model="loadingDialog" persistent width="450">
      <v-card color="primary">
        <v-card-text class="pt-3 white--text">
          {{ loadingText }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialog config column -->
    <dialogConfigColumn ref="dialogConfigColumn"></dialogConfigColumn>
    <dialogSetTags
      ref="dialogSetTags"
      :isCancelled="isCancelled"
      @import-is-cancelled="cancelImport"
      @selected-tags="saveSelectedTags"
    ></dialogSetTags>
  </v-card>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import { mdiDeleteOutline, mdiLabel } from '@mdi/js'
import axios from 'axios'
import { fetchGoogleSheetsData } from 'google-sheets-mapper'
import _uniq from 'lodash/uniq'
import _uniqBy from 'lodash/uniqBy'
import Vue from 'vue'
import VuePapaParse from 'vue-papa-parse'
import { uuid } from 'vue-uuid'
import XLSX from 'xlsx'
import firestoreDbMixin from '../../mixins/firestoreDbMixin'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import dialogSetTags from '../component/DialogSetTags.vue'
import dialogConfigColumn from './DialogConfigColumn.vue'

const vCard = require('vcf')

Vue.use(VuePapaParse)

export default {
  components: {
    dialogConfigColumn,
    dialogSetTags,
  },
  mixins: [pouchDbMixin, firestoreDbMixin],
  data: () => ({
    // search table
    search: '',
    sortBy: 'name',
    sortDesc: false,

    dialogImport: false,
    dialogImportPreview: false,
    mappingData: false,
    alldata: false,

    resolve: null,
    reject: null,
    tab: null,
    icons: {
      mdiLabel,
      mdiDeleteOutline,
    },
    importPreviewShowFields: false,
    iframeSrc: null,
    fieldName: '',
    fieldPhoneNumber: '',
    fieldSource: '',
    qrId: '',
    fieldListName: '',
    fieldValue: [],

    headersImport: [],
    dataImport: [],
    selectedTableData: [],
    importPreviewData: [],
    listFields: [],
    listName: [],
    listNameImport: ['CSV', 'vCard', 'WA', 'WA Group', 'Gsheets', 'Excel'],

    waContacts: [],

    // CSV
    isImportCsvOngoing: false,
    importFileCsv: null,

    // EXCEL
    isImportExcelOngoing: false,
    importFileExcel: null,
    files: [],

    // VCF
    isImportVcfOngoing: false,
    importFileVcf: null,

    // WA
    selectedInstance: null,
    contactChannelLoading: true,

    // WA GROUP
    selectedGroup: null,
    groupList: [],
    contactGroupLoading: true,

    // GSHEET
    linkGsheet: '',

    // ALERT
    dialogAlert: false,
    alertText: '',

    // LOADING
    loadingDialog: false,

    // LABEL
    labelData: [],
    selectedLabel: [],

    // DB SETTING
    dbSettings: null,
    dbRemoteSettings: null,
    columnsSetting: null,
    tableColumns: [],

    idlist: '',
    currentTotalContacts: 0,

    // listInstance: [],
    openSelect: false,
    tags: ['Tags 1', 'Tags 2'],
    listGroup: [],
    chosenGroup: {},
    listInstance: [],
    // selectedTags: []
  }),
  props: {
    selectedTags: { type: Array, default: () => [] },
    allTags: { type: Array, default: () => [] },
    selected: { type: Object, default: () => ({}) },
    isCancelled: { type: Boolean, default: false },
  },
  computed: {
    allContactData() {
      return this.$store.getters['global/getAllContactData']
    },
    loadingText() {
      return this.$t('loadingDialog')
    },

    editableColumn() {
      return this.tableColumns
        .filter(x => x.value !== 'id' && x.value !== 'label' && x.value !== 'phone_number' && x.value !== 'status')
        .sort((a, b) => (a.order_number < b.order_number ? -1 : 0))
    },
    additionalColumn() {
      return this.tableColumns.filter(x => x.deleteable)
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    allContactData() {
      return this.$store.getters['global/getAllContactData']
    },
    // listInstance() {
    //   const data = this.$store.getters['auth/getListInstance']
    //   return !(data?.value)?[]:data.value.filter(x => {
    //     if (x.status == 1 && !x.hasOwnProperty('clientSecret') && x.type == !'chatwoot') {
    //       x.label =
    //         x.label.startsWith('-') || x.label.includes('Default') || x.label.includes('62') ? x.label_server : x.label

    //       return true
    //     }

    //     return false
    //   })

    //   // .filter(x => x)
    //   // .map(obj => {
    //   //   const newObj = {}
    //   //   newObj.label = obj.label
    //   //   newObj.value = `${obj.sub_id}-${obj.instance_id}`

    //   //   return newObj
    //   // })
    // },
  },
  watch: {
    alldata() {
      if (this.alldata) {
        this.selectedTableData = this.dataImport

        // //console.log(this.selectedTableData, 'this.selectedTableData')
      } else {
        this.selectedTableData = []
      }
    },
    tab(val) {
      if (val) {
        this.selectedInstance = ''
        this.chosenGroup = {}
      }
    },
  },
  beforeDestroy() {
    // window.removeEventListener('message', this.receiveMessage, false)
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
  },

  async mounted() {
    const data = await this.$store.dispatch('auth/getAllInstances')
    this.listInstance = data?.value || []
    return !data?.value
      ? []
      : data.value.filter(x => {
          if (x.status == 1 && !x.hasOwnProperty('clientSecret') && x.type == !'chatwoot') {
            x.label =
              x.label.startsWith('-') || x.label.includes('Default') || x.label.includes('62')
                ? x.label_server
                : x.label

            return true
          }

          return false
        })
    // window.addEventListener('message', this.getImportData, false)
  },

  methods: {
    async saveSelectedTags(selected, tags) {
      this.$emit('selected-tags', selected)
    },
    async fetchGroup(data) {
      try {
        const listGroup = await clientPocketBase.collection('crm_contact').getFullList(400, {
          filter: `subId = '${this.user.sub_id}' && phone_number_show = 'Group' && instance = '${this.$Md5Hash(data)}'`,
          $autoCancel: false,
        })
        this.listGroup = listGroup
      } catch (error) {
        console.log(error, 'ada error fetchGroup')
      }
    },
    cancelImport() {
      // console.log('masuk sini<')
      this.$emit('import-is-cancelled', false)
    },
    getImportUrl(additionalData) {
      if (additionalData) {
        return encodeURI(`${process.env.VUE_APP_IMPORT_FILE_URL}?fields=${additionalData}`)
      }

      return encodeURI(process.env.VUE_APP_IMPORT_FILE_URL)
    },
    getImportData({ origin, data }) {
      // //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1781 ~ getImportData ~ data', data, origin)
    },
    async open(labelData, listName = [], idlist = '', currentTotalContacts = 0) {
      this.currentTotalContacts = currentTotalContacts
      this.dialogImport = true
      this.idlist = idlist

      this.fieldListName = ''
      this.fieldValue = []

      // this.listInstance = []
      this.groupList = []

      this.loadingDialog = true

      // const dataGroup = await this.mixinGetContactGroup()
      this.contactGroupLoading = false

      // await this.fdbGetAllContactFromRoom(this.user.sub_id)
      // this.connectContacts(false, false)
      // const itemRoom = await this.loadContacts(true)
      // const query = {
      //   token: this.user.token,
      //   sub_id: this.user.sub_id,
      // }
      // const roomsFromPg = await this.$store.dispatch('rooms/getRoomsContactImportPostgres', {
      //   sub_id: this.user.sub_id
      // })
      // if (roomsFromPg.status) {
      //   this.groupList = roomsFromPg.data.data.filter(x => x.phone_number_show === 'Group' && x.users)
      //   this.waContacts = roomsFromPg.data.data

      //   //console.log('this.waContacts: ', this.waContacts)
      // }

      // await this.connectSettings(false, false)
      // await this.loadSetting()
      this.contactChannelLoading = false

      this.loadingDialog = false
      this.search = ''

      if (listName.length > 0) {
        listName.forEach(list => {
          if (list && list.deleteable) {
            this.listName.push({
              label: list.list_name,
              value: list.id,
            })
          }
        })
      }
      if (this.listName.length > 0) this.fieldListName = this.listName[0].value

      // this.dialogImportPreview = true
      this.selectedInstance = null
      this.selectedGroup = null
      this.importFileCsv = null
      this.importFileVcf = null

      this.isImportVcfOngoing = false
      this.isImportCsvOngoing = false
      this.mappingData = false
      this.alldata = false
      this.qrId = uuid.v1()

      this.iframeSrc = `${process.env.VUE_APP_WA_SERVICES_URL}register-channel-pg/${this.qrId}`

      this.fieldName = ''
      this.fieldPhoneNumber = ''
      this.linkGsheet = ''
      this.fieldSource = `Import ${uuid.v1()}`

      this.headersImport = [
        { text: this.$t('phoneNumber'), value: 'phone_number' },
        { text: this.$t('name'), value: 'name' },
        { text: 'Type', value: 'type' },
      ]
      this.dataImport = []
      this.importPreviewData = []
      this.listFields = []
      this.selectedTableData = []

      this.labelData = labelData
      this.selectedLabel = []
      this.labelData.forEach(x => {
        this.selectedLabel.push(false)
      })

      window.addEventListener('message', this.receiveMessageIndex, false)

      // this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_SETTINGS, payload => {
      //   this.loadSetting()
      // })

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    getContactName(phoneNumber, roomName) {
      // const userData = await this.$store.dispatch('inboxNavbar/getUserData', {
      //   phone_number: phoneNumber,
      //   sub_id: this.user.sub_id,
      //   token: this.user.token,
      // })

      const userData = this.allContactData.find(contact => contact.phone_number == phoneNumber)

      // //console.log(userData)
      if (userData) {
        if (userData.bailey_name) {
          return userData.bailey_name
        }

        if (userData.notify_name) {
          return userData.notify_name
        }

        if (userData.contact_list_name) {
          return userData.contact_list_name
        }

        if (userData.username) {
          return userData.username
        }
      }
      if (roomName !== phoneNumber) {
        return roomName
      }

      return phoneNumber
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      }

      return phoneNumber
    },
    selectTags() {
      const self = this
      // self.openSelect = true
      this.$refs.dialogSetTags.open('add')
      // self.dialogImport = false
      // self.dialogImportPreview = false
    },
    closeTag() {
      this.openSelect = false
    },
    async agree() {
      // //console.log('masuk agree')
      switch (this.tab) {
        case 0:
          if (this.importFileCsv) {
            this.isImportCsvOngoing = true
            this.$papa.parse(this.importFileCsv, {
              header: true,
              skipEmptyLines: true,
              complete: this.onCompleteImportCsv,
            })
          } else {
            this.alertText = this.$t('dialog.invalidFile')
            this.dialogAlert = true
          }
          break
        case 1:
          if (this.importFileVcf) {
            this.isImportVcfOngoing = false
            this.importPreviewShowFields = false
            this.dialogImportPreview = true
            this.dialogImport = false
            this.onCompleteImportVcf()
          } else {
            this.alertText = this.$t('dialog.invalidFile')
            this.dialogAlert = true
          }
          break
        case 2:
          if (this.selectedInstance) {
            this.loadingDialog = true
            this.dataImport = []

            const users = []
            const roomsFromPg = await this.$store.dispatch('rooms/getRoomsContactImportPostgres', {
              sub_id: this.user.sub_id,
              instances: this.selectedInstance,
            })
            // console.log(roomsFromPg, 'ini nyund')
            if (roomsFromPg.status) {
              // kayanya ga dipake
              // this.groupList = roomsFromPg.data.filter(x => x.phone_number_show === 'Group' && x.users)
              this.waContacts = roomsFromPg.data
            }
            for (const group of this.groupList) {
              for (const user of group.users) {
                // //console.log(user, 'ini bus')
                const findIndex = users.findIndex(ele => ele._id === user._id)
                if (findIndex === -1) {
                  users.push(user._id)
                }
              }
            }
            const groupContact = _uniqBy(this.groupList, obj => obj.phone_number)
            groupContact.forEach(el => {
              el._uid = this.$nanoid()
            })

            // users = _uniq(users)
            this.dataImport = this.waContacts.filter(x => {
              if (
                x !== undefined &&
                x.phone_number &&
                x.phone_number_show !== 'Group' &&
                !x.phone_number.startsWith('-') &&
                !x.phone_number.includes(users).length
                // && x._id === this.selectedInstance
              ) {
                const name = this.getContactName(x.phone_number, x.name)
                x._uid = this.$nanoid()
                x.name = name

                return true
              }

              return false
            })

            this.dataImport = _uniqBy(this.dataImport, obj => obj.phone_number)
            this.dataImport.push(...groupContact)

            this.dataImport = this.dataImport.filter(el => {
              return el.name
            })

            this.dataImport.forEach(el => {
              if (el.phone_number.length > 15) {
                el.type = 'Group'
              } else {
                el.type = 'Personal'
              }
            })

            //         this.dataImport = this.waContacts.filter(
            //           x =>
            // x.phone_number &&
            //             x.phone_number_show !== 'Group' &&
            //             !x.phone_number.startsWith('-') &&
            //             !x.phone_number.includes(users) &&
            //             x.instance_id === this.selectedInstance
            //         )

            //         this.dataImport.forEach(el => {
            //           // eslint-disable-next-line no-underscore-dangle
            //           el._uid = this.$nanoid()
            //         })
            this.mappingData = true
            this.dialogImportPreview = true

            // const res = await this.$store.dispatch('auth/importListContact', {
            //   id: this.selectedInstance,
            //   idlist: this.idlist,
            // })
            // if (res.status) {
            //   this.dataImport = res.waContacts
            //   this.mappingData = true
            //   this.dialogImportPreview = true
            // } else {
            //   this.alertText = this.$t('dialog.failedgetdata')
            //   this.dialogAlert = true
            // }
            // this.dataImport = _uniqBy(this.dataImport, 'phone_number')
            // this.dataImport = _sortBy(this.dataImport, ['name'])

            // //console.log(this.dataImport, `CASE 2`)
            this.loadingDialog = false
          } else {
            this.alertText = this.$t('dialog.channelRequired')
            this.dialogAlert = true
          }
          break
        case 3:
          if (this.selectedInstance) {
            if (Object.keys(this.chosenGroup).length) {
              this.loadingDialog = true
              // this.dataImport = []
              if (!this.chosenGroup.users || this.chosenGroup.users.length === 0) {
                try {
                  const data = await axios.get(this.chosenGroup.profile_picture)
                  const chosen = await clientPocketBase
                    .collection('crm_contact')
                    .getOne(this.chosenGroup.id, { $autoCancel: false })
                  this.chosenGroup.users = chosen.users
                } catch (error) {
                  console.log(error, 'err<')
                }
              }

              this.dataImport = this.chosenGroup.users
                ? this.chosenGroup.users.map(ele => {
                    return {
                      phone_number: ele._id,
                      // name: this.allContactData.find(element => element.phone_number === ele._id)
                      //   ? this.allContactData.find(element => element.phone_number === ele._id).name
                      //   : ele._id,
                      name: ele.username || ele._id,
                      type: 'Personal',
                      _uid: ele._id,
                      profile_picture: ele.avatar,
                    }
                  })
                : []
              this.chosenGroup = {}
              this.selectedInstance = ''
              this.mappingData = true
              this.dialogImportPreview = true
              this.loadingDialog = false
            } else {
              this.alertText = `Please select Group you want to import`
              this.dialogAlert = true
            }
          } else {
            this.alertText = this.$t('dialog.channelRequired')
            this.dialogAlert = true
          }
          break
        // if (this.selectedGroup) {
        //   this.loadingDialog = true

        //   // const importData = await this.checkGroupMember(
        //   //   `${this.selectedGroup.phone_number}@g.us`,
        //   //   this.selectedGroup._id,
        //   // )
        //   // if (importData.length > 0) {
        //   // if (this.selectedGroup.users) {
        //   //   this.sele
        //   // }
        //   // this.waContacts
        //   this.dataImport = []

        //   // //console.log(this.selectedGroup, this.groupList, `INI TEST`)
        //   const response = await this.$store.dispatch('inboxNavbar/getUsers', {
        //     subId: this.user.sub_id,
        //     roomId: this.selectedGroup.roomId,
        //   })

        //   if (response.status) {
        //     for (const x of response.data) {
        //       const contact = this.waContacts.find(y => y.phone_number == x.username)
        //       if (contact) {
        //         contact._uid = this.$nanoid()
        //         contact.name = !contact.name ? contact.phone_number : contact.name
        //         this.dataImport.push({
        //           ...contact,
        //         })
        //       } else {
        //         this.dataImport.push({
        //           name: x.username,
        //           phone_number: x._id,
        //           phone_number_show: x._id,
        //           _uid: this.$nanoid(),
        //           profile_picture:
        //             this.listInstance.length > 0
        //               ? `${process.env.VUE_APP_WA_SERVICES_URL}ppic/${this.listInstance[0].sub_id}-${this.listInstance[0].instance_id}/${x._id}/a.jpg`
        //               : '',
        //         })
        //       }
        //     }
        //   }
        //   this.mappingData = true
        //   this.dialogImportPreview = true

        //   this.loadingDialog = false

        //   // } else {
        //   //   this.alertText = this.$t('dialog.errorImportUser')
        //   //   this.dialogAlert = true
        //   // }
        // }

        // // else {
        // //   this.alertText = this.$t('dialog.selectGroupContact')
        // //   this.dialogAlert = true
        // // }
        // this.dataImport = _uniqBy(this.dataImport, obj => obj.phone_number)
        // this.dataImport = _sortBy(this.dataImport, ['name'])
        // //console.log(this.dataImport, 'data')
        // this.loadingDialog = false
        // break
        case 4:
          if (
            new RegExp('/spreadsheets/d/([a-zA-Z0-9-_]+)').exec(this.linkGsheet) !== null &&
            new RegExp('/spreadsheets/d/([a-zA-Z0-9-_]+)').exec(this.linkGsheet).length > 0
          ) {
            const spreadsheetId = new RegExp('/spreadsheets/d/([a-zA-Z0-9-_]+)').exec(this.linkGsheet)[1]
            const sheetsName = []
            this.loadingDialog = true
            try {
              const getData = await fetchGoogleSheetsData({
                apiKey: process.env.VUE_APP_GSHEET_API_KEY,
                sheetId: spreadsheetId,
              })
              if (getData.length > 0) {
                getData.forEach((x, index) => {
                  const contacts = []
                  for (const dataGsheet of x.data) {
                    const contact = {
                      _uid: this.$nanoid(),
                    }
                    for (const field in dataGsheet) {
                      contact[field] = dataGsheet[field]
                    }
                    contacts.push(contact)
                  }
                  sheetsName.push({
                    idx: index,
                    name: x.id,
                    data: contacts,
                    selected: false,
                  })
                })
                this.dialogImportPreview = true
                this.importPreviewData = sheetsName[0].data

                if (this.importPreviewData.length > 0) {
                  for (const field in this.importPreviewData[0]) {
                    if (field !== '_uid') this.listFields.push(field)
                  }
                }

                this.dialogImport = false
              }
            } catch (error) {
              this.alertText = error
              this.dialogAlert = true
            }

            this.loadingDialog = false
          } else {
            this.alertText = this.$t('dialog.notvalidsheet')
            this.dialogAlert = true
          }
          break
        case 5:
          if (this.importFileExcel) {
            this.dialogImportPreview = true
            const reader = new FileReader()
            reader.readAsBinaryString(this.importFileExcel)
            // eslint-disable-next-line no-shadow
            reader.onload = async e => {
              const binaryData = e.target.result
              const workbook = XLSX.read(binaryData, { type: 'binary' })
              let data = []

              // //console.log(workbook)
              workbook.SheetNames.forEach(el => {
                data = XLSX.utils.sheet_to_json(workbook.Sheets[el])
              })
              const tempField = []
              data.forEach(el => {
                for (const key in el) {
                  tempField.push(key)
                }
              })
              this.listFields = _uniq(JSON.parse(JSON.stringify(tempField)))
              this.headersImport = [
                { text: this.$t('name'), value: this.listFields[0] },
                { text: this.$t('phoneNumber'), value: this.listFields[1] },
              ]
              this.importPreviewData = data
              this.dialogImportPreview = true
            }
          }
          break
        default:
          this.resolve({
            status: false,
            data: [],
          })
          this.dialogImport = false
          break
      }
    },
    async importContact() {
      if (this.selectedTableData.length === 0) {
        this.alertText = this.$t('dialog.pleaseselectone')
        this.dialogAlert = true

        return
      }

      const indexSave = []
      const tempContact = []

      for (const [i, data] of this.selectedTableData.entries()) {
        if (data.phone_number_show === 'Group') {
          indexSave.push(i)

          if (data.users?.length) {
            for (const user of data.users) {
              const contact = this.waContacts.find(ele => ele.phone_number === user.username)
              if (contact) {
                contact._uid = this.$nanoid()
                contact.name = !contact.name ? contact.phone_number : contact.name
                contact.created_at = new Date().getTime()
                contact.updated_at = new Date().getTime()
                tempContact.push({
                  ...contact,
                  platform: 'wa',
                })
              } else {
                tempContact.push({
                  name: user.username,
                  phone_number: user._id,
                  phone_number_show: user._id,
                  _uid: this.$nanoid(),
                  created_at: new Date().getTime(),
                  updated_at: new Date().getTime(),
                  profile_picture:
                    this.listInstance.length > 0
                      ? `${process.env.VUE_APP_WA_SERVICES_URL}ppic/${this.listInstance[0].sub_id}-${this.listInstance[0].instance_id}/${user._id}/a.jpg`
                      : '',
                  platform: 'wa',
                })
              }
            }
          }
        }
        data.created_at = new Date().getTime()
        data.updated_at = new Date().getTime()
      }

      // data.source = this.fieldSource
      if (indexSave.length > 0) {
        this.selectedTableData.push(...tempContact)
        this.selectedTableData = this.selectedTableData.filter(el => {
          // //console.log(el, 'ini elddd')

          return el.phone_number.length < 15 && (!el.users || el.users.length < 3)
        })
      }

      this.resolve({
        status: true,
        data: this.selectedTableData,
        dataLabel: this.labelData,
        selectedLabel: this.selectedLabel,
        idlist: this.fieldListName,
      })
      this.dialogImport = false
      this.dialogImportPreview = false
      this.importFileExcel = null
      this.$emit('import-is-cancelled', false)
      this.$refs.dialogSetTags.resetState()

      window.removeEventListener('message', this.receiveMessageIndex, false)
    },
    cancel() {
      if (this.resolve) {
        this.resolve({
          status: false,
          data: [],
        })
      }
      this.dialogImport = false
      this.dialogImportPreview = false
      this.importFileExcel = null
      this.tab = null
      this.$emit('import-is-cancelled')

      window.removeEventListener('message', this.receiveMessageIndex, false)
    },
    functionUnique(arr, track = new Set()) {
      return arr.filter(({ id }) => (track.has(id) ? false : track.add(id)))
    },
    onCompleteImportCsv(result, file) {
      const csvImportData = result.data
      const rows = []

      csvImportData.forEach(data => {
        data._uid = uuid.v1()
        rows.push(data)
      })

      this.isImportCsvOngoing = false
      this.importPreviewShowFields = true
      this.dialogImportPreview = true
      this.dialogImport = false
      this.importPreviewData = csvImportData
      if (this.importPreviewData.length > 0) {
        for (const field in this.importPreviewData[0]) {
          if (field !== '_uid') this.listFields.push(field)
        }
      }

      this.dialogImport = false
    },
    onCompleteImportVcf() {
      const self = this
      const reader = new FileReader()

      reader.readAsText(this.importFileVcf)

      reader.onload = async function () {
        const str = reader.result.replace(/(?<!\r)\n/g, '\r\n')

        const cards = vCard.parse(str)
        const data = []
        self.loadingDialog = true
        cards.forEach((item, i) => {
          // const name = item.data.fn ? item.data.fn._data : ''
          // const phoneNumber = item.data.tel ? item.data.tel : ''
          if (item.data.tel || (item.data.fn && item.data.tel)) {
            const name = item.data.fn._data || ''
            const phoneNumber = item.data.tel
            if (Array.isArray(phoneNumber)) {
              phoneNumber.forEach(phone => {
                phone._data = phone._data.replace(/\D/g, '')
                const contact = {
                  _uid: self.$nanoid(),
                  _id: phone._data,
                  phone_number: phone._data,
                }
                if (item.data.org) {
                  contact.name = name.length > 20 ? item.data.org._data : name
                  data.push(contact)
                } else {
                  contact.name = name
                  data.push(contact)
                }
              })
            } else {
              phoneNumber._data = phoneNumber._data.replace(/\D/g, '')
              const contact = {
                _uid: self.$nanoid(),
                _id: phoneNumber._data ? phoneNumber._data : '',
                name,
                phone_number: phoneNumber._data ? phoneNumber._data : '',
              }

              data.push(contact)
            }
          }
        })

        self.importPreviewData = _uniqBy(data, obj => obj.phone_number)

        self.dataImport = await self.formatPhoneNumber()
        self.mappingData = true

        self.loadingDialog = false
      }

      reader.onerror = function () {
        self.$q.dialog({
          title: 'Failed',
          message: reader.error,
        })
      }
    },
    async mapping() {
      this.loadingDialog = true

      if (!this.fieldName) {
        this.alertText = this.$t('dialog.mappingallfield')
        this.dialogAlert = true
        this.loadingDialog = false

        return
      }
      if (!this.fieldPhoneNumber) {
        this.alertText = this.$t('dialog.mappingallfield')
        this.dialogAlert = true
        this.loadingDialog = false

        return
      }
      if (this.headersImport.length === 0) {
        this.headersImport = [
          { text: this.$t('phoneNumber'), value: 'phone_number' },
          { text: this.$t('name'), value: 'name' },
        ]
      }

      this.additionalColumn.forEach((data, index) => {
        const obj = this.headersImport.find(x => x.value === data.value)
        if (!obj && this.fieldValue[index]) {
          this.headersImport.push({ text: data.text, value: data.value })
        }
      })
      this.importPreviewData.forEach(item => {
        item._uid = this.$nanoid()
        item.name = item[this.fieldName]
        if (item[this.fieldPhoneNumber].startsWith('08')) {
          item[this.fieldPhoneNumber] = item[this.fieldPhoneNumber].replace(/^.{2}/g, '628')
          item._id = item[this.fieldPhoneNumber].toString()
          // console.log(item._id, 'wuu')
        } else {
          item._id = item[this.fieldPhoneNumber].toString()
        }

        item.phone_number = item[this.fieldPhoneNumber].toString()
        this.additionalColumn.forEach((data, index) => {
          if (this.fieldValue[index]) {
            item[data.value] = item[this.fieldValue[index]]
          }
        })

        this.contactListTable.forEach(el => {
          if (el.phone_number === item.phone_number) {
            item.has_wa = 'Yes'
          } else {
            item.has_wa = ''
          }
          item.created_at = Date.now()
          item.updated_at = Date.now()
        })
      })
      this.importPreviewData = [...new Map(this.importPreviewData.map(v => [v._id, v])).values()]

      this.dataImport = await this.formatPhoneNumber()
      this.mappingData = true

      this.loadingDialog = false
    },
    async formatPhoneNumber() {
      const modifiedData = []

      const source = await this.checkValidWa()

      this.importPreviewData.forEach(item => {
        if (source.length !== 0) {
          source.forEach(el => {
            if (item.name) {
              if (item.phone_number === el.phone_number) {
                item.has_wa = el.has_wa || ''
                if (item.phone_number) {
                  if (item.phone_number.includes('-') || item.phone_number.length > 16) {
                    item.phone_number_show = 'Group'
                    modifiedData.push(item)
                  } else {
                    item.phone_number = item.phone_number.trim().replace(/[a-zA-Z]/g, '')
                    item.phone_number = item.phone_number.trim().replace(/\D/g, '')

                    if (item.phone_number.startsWith('0')) {
                      item.phone_number = item.phone_number.replace('0', '62')
                    }

                    if (
                      /\d{10,11}/.test(item.phone_number.trim()) ||
                      /\d{12,13}/.test(item.phone_number.trim()) ||
                      /\d{14,15}/.test(item.phone_number.trim())
                    ) {
                      if (
                        item.phone_number.trim().length == 10 ||
                        item.phone_number.trim().length == 11 ||
                        item.phone_number.trim().length == 12 ||
                        item.phone_number.trim().length == 13 ||
                        item.phone_number.trim().length == 14 ||
                        item.phone_number.trim().length == 15
                      ) {
                        item._uid = this.$nanoid()
                        item._id = item.phone_number.trim()
                        item.phone_number_show = item.phone_number.trim()

                        modifiedData.push(item)
                      }
                    }
                  }
                }
              }
            }
          })
        } else if (source.length === 0) {
          if (item.name) {
            item.has_wa = ''
            if (item.phone_number) {
              if (item.phone_number.includes('-') || item.phone_number.length > 16) {
                item.phone_number_show = 'Group'
                modifiedData.push(item)
              } else {
                item.phone_number = item.phone_number.trim().replace(/[a-zA-Z]/g, '')
                item.phone_number = item.phone_number.trim().replace(/\D/g, '')

                if (item.phone_number.startsWith('0')) {
                  item.phone_number = item.phone_number.replace('0', '62')
                }

                if (
                  /\d{10,11}/.test(item.phone_number.trim()) ||
                  /\d{12,13}/.test(item.phone_number.trim()) ||
                  /\d{14,15}/.test(item.phone_number.trim())
                ) {
                  if (
                    item.phone_number.trim().length == 10 ||
                    item.phone_number.trim().length == 11 ||
                    item.phone_number.trim().length == 12 ||
                    item.phone_number.trim().length == 13 ||
                    item.phone_number.trim().length == 14 ||
                    item.phone_number.trim().length == 15
                  ) {
                    item._uid = this.$nanoid()
                    item._id = item.phone_number.trim()
                    item.phone_number_show = item.phone_number.trim()

                    modifiedData.push(item)
                  }
                }
              }
            }
          }
        }
      })

      return modifiedData
    },
    async receiveMessageIndex(event) {
      if (
        event.origin === process.env.VUE_APP_WA_SERVICES_URL.substr(0, process.env.VUE_APP_WA_SERVICES_URL.length - 1)
      ) {
        if (event.data.err) {
          // //console.log(event.data.err)
        } else {
          this.loadingDialog = true
          const res = await this.$store.dispatch('auth/importListContact', {
            id: this.selectedInstance,
            idlist: this.idlist,
          })
          if (res.status) {
            this.dataImport = res.data
            this.mappingData = true
            this.dialogImportPreview = true
          } else {
            this.alertText = this.$t('dialog.failedgetdata')
            this.dialogAlert = true
          }

          this.loadingDialog = false
        }
      }
    },
    async checkValidWa() {
      return []

      // const phoneNumbers = []
      // const validPhoneNumber = []
      // for (var x of this.importPreviewData) {
      //   // this.importPreviewData.forEach(x => {
      //   // validPhoneNumber.push(x)
      //   phoneNumbers.push(x.phone_number)
      //   const contact = {}
      //   contact._id = x._id
      //   contact.phone_number = x.phone_number
      //   contact.phone_number_show = x.phone_number_show
      //   contact.name = x.name
      //   contact.has_wa = ''
      //   this.headersImport.forEach(data => {
      //     if (data.value !== 'phone_number' && data.value !== 'phone_number_show' && data.value !== 'name') {
      //       contact[data.value] = x[data.value]
      //     }
      //   })
      //   validPhoneNumber.push(contact)

      //   // })
      // }

      // // const instanceId = `${this.listInstance[0].sub_id}-${this.listInstance[0].instance_id}`
      // // const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}exists/${instanceId}`

      // // await this.$axiosLimit
      // //   .post(endpoint, { phone_numbers: phoneNumbers })
      // //   .then(response => {
      // //     this.importPreviewData.forEach(x => {
      // //       if (response.data.data[x.phone_number]) {
      // // const contact = {}
      // // contact._id = x._id
      // // contact.phone_number = x.phone_number
      // // contact.phone_number_show = x.phone_number_show
      // // contact.name = x.name
      // // contact.has_wa = 'Yes'
      // // this.headersImport.forEach(data => {
      // //   if (data.value !== 'phone_number' && data.value !== 'phone_number_show' && data.value !== 'name') {
      // //     contact[data.value] = x[data.value]
      // //   }
      // // })
      // //         validPhoneNumber.push(contact)
      // //       }
      // //     })
      // //   })
      // //   .catch(error => {
      // //     console.log(error)
      // //   })

      // return validPhoneNumber
    },

    async loadSetting() {
      // const items = await this.loadSettings()

      // const instances = items.find(x => x._id === 'instances')
      // const instances = this.$store.getters['auth/getListInstance']
      // const instanceValues = instances ? instances.value : []

      // this.listInstance = instanceValues
      //   .filter(x => x.status === 1)
      //   .map(obj => {
      //     const newObj = {}
      //     newObj.label = obj.label
      //     newObj.value = `${obj.sub_id}-${obj.instance_id}`

      //     return newObj
      //   })
      // const colSetting = items.find(x => x._id === 'columns')
      // const tempTableValue = colSetting
      //   ? colSetting.value.filter(
      //       x => x.value === 'phone_number' || x.value === 'phone_number_show' || x.value === 'name',
      //     )
      //   : []
      if (this.idlist && this.idlist !== 'default') {
        const res = await this.$store.dispatch('global/getContactLists', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          list_id: this.idlist,
        })
        if (res.status) {
          // this.listContactValue = res.data
          this.tableColumns = res.data.columns
        }

        // const colSettingList = items.find(x => x._id === `${this.idlist}columns`)
        // if (colSettingList) {
        //   this.columnsSetting = colSettingList
        //   this.tableColumns = colSettingList.value
        // } else {
        //   await this.updateSettings(
        //     {
        //       _id: `${this.idlist}columns`,
        //       key: `${this.idlist}columns`,
        //       value: tempTableValue,
        //     },
        //     async x => {
        //       this.columnsSetting = await this.loadSettingsById(x.id)
        //       this.tableColumns = this.columnsSetting.value
        //     },
        //   )
        // }
      } else {
        const columnsPg = await this.$store.dispatch('global/getSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'columns',
        })

        if (columnsPg.status) {
          this.tableColumns = columnsPg.data.value.filter(
            x => x.value === 'phone_number' || x.value === 'phone_number_show' || x.value === 'name',
          )
        }
      }
    },
    addNewColumn() {
      this.$refs.dialogConfigColumn.open(this.editableColumn, this.idlist)
    },
  },
}
</script>

<style scoped>
.vs__dropdown-option--disabled {
  display: none;
}
</style>
