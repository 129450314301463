<template>
  <v-card>
    <!-- dialog select list -->
    <v-dialog v-model="dialogOperator" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('Select Labels') }}
          </h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <!-- <v-divider /> -->
        <div class="px-2 mx-3 mt-3">
          <v-card elevation="0" outlined height="373" width="523" class="mb-8 mx-auto py-5 px-8 scroll">
            <div class="text-center">
              <v-progress-circular
                v-if="isLoadingTag"
                indeterminate
                color="primary"
                class="mx-16"
                size="100"
              ></v-progress-circular>
            </div>
            <v-row v-if="!isFromRoom" v-for="(column, index) of tags" :key="index" class="pa-0 ma-0">
              <v-col cols="5" dense class="pa-0 ma-0 d-flex align-self-start">
                <v-checkbox
                  v-model="model.optionTags"
                  :label="column.name"
                  color="primary"
                  :id="column.id"
                  :value="column.id"
                  hide-details
                  @change="saveTags(column.id)"
                  :disabled="model.disabledOptionTags.includes(column.id)"
                >
                  <!-- :disabled="isTagForm(column.id)" -->
                  <template #label>
                    <v-icon :color="column.color">
                      {{ icons.mdiLabelVariant }}
                    </v-icon>
                    <span class="ml-1">{{ column.name }}</span>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col dense cols="1" class="pa-0 ma-0 d-flex align-self-center"> </v-col>
              <v-col cols="6" dense class="pa-0 ma-0 mt-3">
                <v-select
                  v-model="model.tags[column.id]"
                  :items="column.variants.variant.map(variant => ({ type: action, value: variant }))"
                  item-text="value"
                  :disabled="
                    typeof model.tags[column.id] === 'undefined' ||
                    !model.optionTags.includes(column.id) ||
                    model.disabledOptionTags.includes(column.id)
                  "
                  dense
                  :label="`${$t('Varian')}`"
                  outlined
                  filled
                  return-object
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="isFromRoom" v-for="(column, i) of filterTags" :key="i" class="pa-0 ma-0">
              <v-col cols="5" dense class="pa-0 ma-0 d-flex align-self-start">
                <v-checkbox
                  v-model="model.optionTags"
                  :label="column.name"
                  color="primary"
                  :id="column.id"
                  :value="column.id"
                  hide-details
                  @change="saveTags(column.id)"
                  :disabled="model.disabledOptionTags.includes(column.id)"
                >
                  <!-- :disabled="isTagForm(column.id)" -->
                  <template #label>
                    <v-icon :color="column.color">
                      {{ icons.mdiLabelVariant }}
                    </v-icon>
                    <span class="ml-1">{{ column.name }}</span>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col dense cols="1" class="pa-0 ma-0 d-flex align-self-center"> </v-col>
              <v-col cols="6" dense class="pa-0 ma-0 mt-3">
                <v-select
                  v-model="model.tags[column.id]"
                  :items="column.variants.variant.map(variant => ({ type: action, value: variant }))"
                  item-text="value"
                  :disabled="
                    typeof model.tags[column.id] === 'undefined' ||
                    !model.optionTags.includes(column.id) ||
                    model.disabledOptionTags.includes(column.id)
                  "
                  dense
                  :label="`${$t('Varian')}`"
                  outlined
                  filled
                  return-object
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
          <div class="mr-5 d-flex justify-end flex-row">
            <v-btn class="mb-8 pa-2" plain @click="cancel">
              <span class="error--text">{{ $t('Cancel') }}</span>
            </v-btn>
            <span>
              <v-btn color="primary" elevation="2" outlined class="mb-8 mr-5 ml-5" @click="openConfig">
                {{ $t('Add') }}
              </v-btn>
            </span>
            <v-btn color="primary" class="mb-8" @click="saveContactsTag">
              {{ $t('Save') }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <dialogLabel ref="dialogLabel" @fetch-tags="fetchContactTag" :tags="tags"></dialogLabel>

    <!-- dialog label -->
    <dialogAddListContact ref="dialogAddListContact"></dialogAddListContact>
  </v-card>
</template>

<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import { mdiLabel, mdiLabelVariant } from '@mdi/js'
import { uuid } from 'vue-uuid'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import dialogLabel from '../component/DialogLabel.vue'
import dialogAddListContact from './DialogAddListContact.vue'

export default {
  components: {
    dialogAddListContact,
    dialogLabel,
  },
  mixins: [pouchDbMixin],
  data: () => ({
    dialogOperator: false,
    resolve: null,
    reject: null,

    dbSettings: null,
    dbRemoteSettings: null,
    contactListSetting: null,
    listContactValue: [],
    listName: [],

    isContact: false,
    chats: [],
    contactChat: null,

    selectedList: '',
    saveText: '',
    listOperator: [],
    setTags: [],

    contactUser: {},
    contactIds: [],
    tags: [],
    model: {
      tags: {},
      optionTags: [],
      disabledOptionTags: [],
    },
    optionTabs: [],
    icons: {
      mdiLabel,
      mdiLabelVariant,
    },
    savedTag: [],
    contactTags: [],
    contactForm: [],
    tagsList: [],
    action: '',
    tagForRoom: [],
    tagForMsg: [],
    filterTags: [],
    selectedAddLabel: {},
    loadingLabel: false,
    getMessage: {},
    isFromRoom: false,
    isLoadingTag: false,
  }),
  props: {
    contacts: { type: Array, default: () => [] },
    isCancelled: { type: Boolean, default: false },
    isCancelledForm: { type: Boolean, default: false },
  },
  emits: ['cancel-form'],
  async mounted() {
    // this.fetchContactForm()
    // this.fetchContactTag()
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
  },
  watch: {
    getMessage(val) {
      // this.getMessage
      // console.log(val, val?.rawData, '<<vira data watch msg')
    },
  },
  methods: {
    selectedTags(e) {
      // return this.model.tags.includes(e) ? false : true
    },
    async fetchContactForm() {
      //filter tag form by contact
      if (this.contactUser) {
        const formResponse = await clientPocketBase.collection('crm_form_response').getFullList(200, {
          filter: `contacts = '${this.contactUser}' && type = 'Response'`,
          expand: 'contacts, responses.form',
        })

        let listFormFiltered = []
        for (let i = 0; i < formResponse.length; i++) {
          const response = formResponse[i]
          for (let j = 0; j < response.expand.responses.length; j++) {
            const idForm = response.expand.responses[j].form
            const contactForm = await clientPocketBase.collection('crm_contact_form').getOne(idForm)
            listFormFiltered.push(contactForm)
          }
        }
        this.contactForm = listFormFiltered
      }
    },
    async fetchContactTag(dataRoom = '') {
      try {
        this.loading = true
        this.isLoadingTag = true
        // setTimeout(async () => {
        const getTags = await clientPocketBase.collection('crm_tags').getFullList(200, { $autoCancel: false })
        this.tags = getTags
        if (this.filterTags) {
          if (this.dataRoom && this.dataRoom.instance) {
            let newTags = []
            for (let i = 0; i < this.tags.length; i++) {
              const element = this.tags[i]
              const found =
                element.channels.channel == null ||
                element.channels.channel.length === 0 ||
                element.channels.channel.find(
                  el => el.id === this.dataRoom.instance?.id || el.id === this.$Md5Hash(this.dataRoom.instance),
                )
              if (found) {
                newTags.push(element)
              }
            }
            this.filterTags = newTags
          } else if (this.dataRoom && typeof this.dataRoom === 'string') {
            let newTags = []
            for (let i = 0; i < this.tags.length; i++) {
              const element = this.tags[i]
              const found =
                element.channels.channel == null ||
                element.channels.channel.length === 0 ||
                element.channels.channel.find(el => el.id === this.dataRoom)
              if (found) {
                newTags.push(element)
              }
            }
            this.filterTags = newTags
          }
        }
        // console.log(JSON.stringify(this.filterTags))
        this.loading = false
        // })
      } catch (error) {
        console.log(error)
      }
      this.isLoadingTag = false
    },
    async openConfig() {
      // this.openTag = true
      this.$refs.dialogLabel.open(this.tags).then(async confirm => {})
      // await this.fetchContactTag()
    },
    async saveTags(data) {
      if (this.model.tags[data] || this.model.tags[data]?.value) {
        const id = this.$Md5Hash(`${data}-${this.dataRoom?.source_id || this.contactUser}`)
        try {
          const selectContactTags = await clientPocketBase
            .collection('crm_contact_tags')
            .getOne(id, { $autoCancel: false })
          if (selectContactTags) {
            this.model.tags = {
              ...this.model.tags,
              [data]: null,
            }
          }
        } catch (err) {
          delete this.model.tags[data]
        }
      } else {
        this.model.tags = {
          ...this.model.tags,
          [data]: {
            value: '',
            type: this.action,
          },
        }
      }
      // console.log(this.model.tags, '<<vira data')
    },
    async open(action, data, contact, isRoom) {
      // console.log(
      //   { data, contact },
      //   { action, isCancel: this.isCancelled },
      //   this.isCancelled,
      //   this.tags,
      //   { isRoom },
      //   '<<<viraa',
      // )
      this.dialogOperator = true
      this.filterTags = []
      this.tags = []
      this.model = { tags: {}, optionTags: [], disabledOptionTags: [] }
      this.isLoadingTag = true
      if (isRoom === undefined) {
        this.isFromRoom = false
      } else {
        this.isFromRoom = true
      }
      this.contactUser = data
      this.dataRoom = contact
      this.action = action
      if (action === 'edit' || action === 'tags') {
        // await this.fetchContactForm()

        let filterContact = await clientPocketBase.collection('crm_contacts').getOne(this.contactUser, {
          expand: 'tags',
        })
        let getContactTags
        let isExist
        // if (filterContact) {
        //   if (!filterContact.tags || filterContact.tags.length) {
        const tagLastIndex = filterContact?.tags[filterContact.tags.length - 1]
        //     const contactTagId = this.$Md5Hash(`${tagLastIndex}-${this.contactUser}`)
        try {
          getContactTags = await clientPocketBase
            .collection('crm_contact_tags')
            .getFirstListItem(`contacts = '${this.contactUser}' && sourceId = '' && tag = '${tagLastIndex}'`, {
              $autoCancel: false,
            })
        } catch (err) {}
        const disabledOptionTags =
          getContactTags &&
          Object.keys(getContactTags.rawData).filter(
            key =>
              getContactTags.rawData[key] &&
              (getContactTags.rawData[key]?.type === 'tagForm' || getContactTags.rawData[key]?.type === 'addForm'),
          )
        await this.fetchContactTag(data)
        let newTags = []
        if (this.dataRoom && contact.instance) {
          for (let i = 0; i < this.tags.length; i++) {
            const element = this.tags[i]
            const found =
              element.channels.channel == null ||
              element.channels.channel.length === 0 ||
              element.channels.channel.find(el => el.id === contact.instance.id)
            if (found) {
              newTags.push(element)
            }
          }
          this.filterTags = newTags
        }
        this.model = getContactTags
          ? {
              tags: getContactTags.rawData,
              optionTags: Object.keys(getContactTags.rawData),
              disabledOptionTags: disabledOptionTags,
            }
          : { tags: {}, optionTags: [], disabledOptionTags: [] }
        this.model.form = false
      } else if (action === 'form' || action === 'tagForm') {
        let foundForm = await clientPocketBase.collection('crm_contact_form').getOne(this.contactUser)
        await this.fetchContactTag(data)
        this.model = foundForm
          ? { tags: foundForm.raw_data, optionTags: Object.keys(foundForm.raw_data), disabledOptionTags: [] }
          : { tags: {}, optionTags: [], disabledOptionTags: [] }
        this.model.form = true
        this.tagsList = []
      } else if (action === 'msg') {
        //filter channel
        this.loadingLabel = true
        let getMessage
        // const getMessage = await meiliClient.index(`${this.user.sub_id}_messages`).getDocument(contact.id)
        const idTagMsg = this.$Md5Hash(`${this.dataRoom?.source_id}`)
        try {
          getMessage = await clientPocketBase
            .collection('crm_contact_tags')
            .getFirstListItem(`sourceId = '${this.dataRoom?.source_id}'`, { $autoCancel: false })
        } catch (err) {}
        this.getMessage = getMessage
        // if (getMessage?.rawData) {
        await this.fetchContactTag(data)
        let newTags = []
        if (this.dataRoom && contact.instance) {
          for (let i = 0; i < this.tags.length; i++) {
            const element = this.tags[i]
            const found =
              element.channels.channel == null ||
              element.channels.channel.length === 0 ||
              element.channels.channel.find(el => el.id === this.$Md5Hash(contact.instance))
            if (found) {
              newTags.push(element)
            }
          }
          this.filterTags = newTags
        }
        this.model = getMessage?.rawData
          ? {
              tags: getMessage?.rawData,
              optionTags: Object.keys(getMessage?.rawData),
              disabledOptionTags: [],
            }
          : { tags: {}, optionTags: [], disabledOptionTags: [] }
        // console.log(getMessage, '<<Vira msg ')
      } else if (action === 'bulk_tags') {
        await this.fetchContactTag(data)
        //console.log(data, '<<bulk tags vira action')
        this.contactIds = data
        this.model = { tags: {}, optionTags: [], disabledOptionTags: [] }
      } else {
        await this.fetchContactTag(data)
        let newTags = []
        if (this.dataRoom && contact.instance) {
          for (let i = 0; i < this.tags.length; i++) {
            const element = this.tags[i]
            const found =
              element.channels.channel == null ||
              element.channels.channel.length === 0 ||
              element.channels.channel.find(el => el.id === contact.instance.id)
            if (found) {
              newTags.push(element)
            }
          }
          this.filterTags = newTags
        }
        if (this.selectedAddLabel) {
          if (!this.isCancelled && !this.isCancelledForm) {
            const rawData = Object.fromEntries(Object.entries(this.selectedAddLabel).filter(([_, v]) => v != null))
            this.model = { tags: rawData, optionTags: Object.keys(rawData), disabledOptionTags: [] }
          } else {
            this.model = { tags: {}, optionTags: [], disabledOptionTags: [] }
          }
        }
      }
      this.isLoadingTag = false
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async openBatch(status, data, listOperator = []) {
      // this.contactIds = JSON.parse(JSON.stringify(data))
      // this.action = status
      // // await this.fetchContactTag()
      // if (status === 'tags') {
      //   //console.log(this.contactTags, 'ini coton')
      //   let contacts = []
      //   for (let i = 0; i < this.contactIds.length; i++) {
      //     const id = this.contactIds[i]
      //     const test = this.contactTags.find(ele => ele.contacts === id)
      //     //console.log(test, 'ini tester')
      //     if (test) contacts.push(test)
      //   }
      //   //console.log(contacts, 'ini consz')
      //   // const filterContact = this.contactTags.filter(el => el.contacts === this.contactUser)
      //   this.model = contacts.length ? contacts[0].rawData : { tags: [], variants: [] }
      //   this.model.form = false
      //   // await this.fetchContactTag()
      // }
      // this.dialogOperator = true
      // return new Promise((resolve, reject) => {
      //   this.resolve = resolve
      //   this.reject = reject
      // })
    },
    resetState() {
      this.selectedAddLabel = {}
    },
    agree() {
      const self = this
      self.resolve({
        status: true,
        data: this.selectedList,
      })
      this.dialogOperator = false
    },
    cancel() {
      this.filterTags = []
      this.model.tags = {}
      this.resolve({
        status: false,
      })
      this.dialogOperator = false
    },
    async saveContactsTag() {
      this.dialogOperator = false
      const selected = Object.keys(this.model.tags)

      if (this.contactIds?.length) {
        try {
          if (this.action === 'bulk_tags') {
            for (let i = 0; i < selected.length; i++) {
              const tag = selected[i]
              for (let i = 0; i < this.contactIds.length; i++) {
                const idCon = this.contactIds[i].user.id
                const id = this.$Md5Hash(`${tag}-${idCon}`)
                this.contactUser = idCon
                await clientPocketBase.collection('crm_contacts').update(
                  idCon,
                  {
                    tags: selected.filter(key => this.model.tags[key] !== null),
                  },
                  { $autoCancel: false },
                )
                // const allContactTag = await clientPocketBase.collection('crm_contact_tags').getFullList(200, {
                //   filter: `contacts = '${idCon}'`,
                // })
                // if (allContactTag.length) {
                try {
                  await clientPocketBase.collection('crm_contact_tags').delete(id, { $autoCancel: false })
                } catch (err) {}
                // }
                if (this.model.tags[selected[i]] !== null) {
                  const createContactTag = await clientPocketBase.collection('crm_contact_tags').create(
                    {
                      id,
                      tag: selected[i],
                      variant: this.model.tags[selected[i]]?.value,
                      contacts: idCon,
                      subId: this.user.sub_id,
                      rawData: Object.fromEntries(Object.entries(this.model.tags).filter(([_, v]) => v != null)),
                    },
                    { $autoCancel: false },
                  )
                }
                this.contactTagsMaker(this.model.tags)
              }
              // this.$emit('fetch-contact')
              // await this.fetchContactTag()
            }
          }
          this.contactIds = []
          this.model.tags = {}
        } catch (err) {
          this.contactIds = []
          console.log(err)
        }
      } else {
        if (this.action === 'tags') {
          await clientPocketBase.collection('crm_contacts').update(
            this.contactUser,
            {
              tags: selected.filter(key => this.model.tags[key] !== null),
            },
            { $autoCancel: false },
          )
          for (let i = 0; i < selected.length; i++) {
            const id = this.$Md5Hash(`${selected[i]}-${this.contactUser}`)

            try {
              await clientPocketBase.collection('crm_contact_tags').delete(id, { $autoCancel: false })
            } catch (err) {}
            if (this.model.tags[selected[i]] !== null) {
              const createContactTag = await clientPocketBase.collection('crm_contact_tags').create(
                {
                  id,
                  tag: selected[i],
                  variant: this.model.tags[selected[i]]?.value,
                  contacts: this.contactUser,
                  subId: this.user.sub_id,
                  rawData: Object.fromEntries(Object.entries(this.model.tags).filter(([_, v]) => v != null)),
                },
                { $autoCancel: false },
              )
            }
          }

          this.$emit('fetch-contact')
          this.loading = false
          this.model.tags = {}
        } else if (this.action === 'msg') {
          for (let i = 0; i < selected.length; i++) {
            const id = this.$Md5Hash(`${selected[i]}-${this.dataRoom?.source_id}`)
            const customRawData = Object.fromEntries(Object.entries(this.model.tags).filter(([_, v]) => v != null))
            try {
              const deleteTag = await clientPocketBase.collection('crm_contact_tags').delete(
                id,
                // {
                //   tag: selected[i],
                //   variant: this.model.tags[selected[i]].value,
                //   contacts: this.contactUser,
                //   subId: this.user.sub_id,
                //   rawData: customRawData,
                // },
                { $autoCancel: false },
              )
              // console.log(deleteTag, '<<delete 1 dri awal')
            } catch (err) {}
            if (this.model.tags[selected[i]] !== null) {
              try {
                const createContactTag = await clientPocketBase.collection('crm_contact_tags').create(
                  {
                    id,
                    tag: selected[i],
                    variant: this.model.tags[selected[i]].value,
                    contacts: this.contactUser,
                    subId: this.user.sub_id,
                    rawData: customRawData,
                    sourceId: this.dataRoom?.source_id,
                  },
                  { $autoCancel: false },
                )
                // console.log(createContactTag, '<<create 1 dri awal')
              } catch (err) {
                console.log(err)
              }
              // }
            }
            // this.contactTagsMaker(this.model.tags)
            // this.$emit('setMessageLabels', this.dataRoom)
            this.loading = false
          }
          this.$emit('update-selectedWa', this.model.tags, this.dataRoom)
          // this.$emit('update-selectedWa', this.model.tags, this.dataRoom)
          this.model.tags = {}
        } else if (this.action === 'tagForm') {
          this.tagsList = []

          for (let i = 0; i < selected.length; i++) {
            const id = this.$Md5Hash(`${selected[i]}-${this.contactUser}`)
            if (selected[i]) {
              const getOneTag = await clientPocketBase.collection('crm_tags').getOne(selected[i])
              // tagsName.push(getOneTag.name)
              this.tagsList.push({
                name: getOneTag.name,
                color: getOneTag.color,
                variant: this.model.tags[selected[i]].value,
                id: selected[i],
              })
            }
          }
          let updateForm = await clientPocketBase.collection('crm_contact_form').update(this.contactUser, {
            raw_data: Object.fromEntries(Object.entries(this.model.tags).filter(([_, v]) => v != null)),
            tags: this.tagsList,
          })
          this.$emit('fetch-form')

          this.loading = false
          // await this.fetchContactTag()
          this.model.tags = {}
        } else {
          this.selectedAddLabel = this.model.tags
          this.$emit('selected-tags', this.model.tags, this.tags, this.dataRoom)
          this.$emit('selected-fromWA', this.model.tags, this.dataRoom)
          this.$emit('save-tags', this.model.tags)
        }
        // this.$emit('fetch-tagsWA')
        this.$emit('fetch-filterTag')
        this.contactIds = []
      }
      this.$emit('cancel-form', false)
      this.$emit('import-is-cancelled', false)
      this.filterTags = []
    },
    async contactTagsMaker(rawData) {
      //update contact tag & creat contact tag for set tags
      const contact = this.contactUser
      let getRawTag = {}
      try {
        getRawTag = await clientPocketBase
          .collection('crm_contact_tags')
          .getFirstListItem(`contacts='${contact}' && sourceId = ''`, {
            expand: 'tag',
            $autoCancel: false,
          })
      } catch (err) {}
      let newTag = { ...(getRawTag.rawData || {}), ...rawData }
      // let newTag = rawData
      let selected = Object.keys(newTag)
      // console.log({ newTag, rawData, getRawTag, selected }, '<<vira tag tag')
      // this.$emit('update-selectedWa', rawData, this.dataRoom, getRawTag?.rawData)

      const updateContact = await clientPocketBase.collection('crm_contacts').update(
        contact,
        {
          tags: selected.filter(key => rawData[key] !== null),
        },
        { $autoCancel: false },
      )
      for (let i = 0; i < selected.length; i++) {
        let id = this.$Md5Hash(`${selected[i]}-${contact}`)
        // console.log(newTag[selected[i]], selected[i], '<<ini if 1 ok')
        try {
          const deleteTag = await clientPocketBase.collection('crm_contact_tags').delete(id, { $autoCancel: false })
        } catch (err) {}
        if (newTag[selected[i]] !== null) {
          try {
            const updateTag = await clientPocketBase.collection('crm_contact_tags').update(
              id,
              {
                tag: selected[i],
                variant: newTag[selected[i]].value,
                contacts: contact,
                subId: this.user.sub_id,
                rawData: Object.fromEntries(Object.entries(newTag).filter(([_, v]) => v != null)),
              },
              { $autoCancel: false },
            )
            // console.log(updateTag, '<<update to update 3')
          } catch (err) {
            // if (rawData[selected[i]] !== null) {
            try {
              const createContactTag = await clientPocketBase.collection('crm_contact_tags').create(
                {
                  id,
                  tag: selected[i],
                  variant: newTag[selected[i]].value,
                  contacts: contact,
                  subId: this.user.sub_id,
                  rawData: Object.fromEntries(Object.entries(newTag).filter(([_, v]) => v != null)),
                  // msgTagData: thxis.action === 'msg' ? this.model : null,
                },
                { $autoCancel: false },
              )
            } catch (err) {
              console.log(err)
            }
            // }
          }
        }
      }
      // console.log(selected, newTag, '<<akhir nih')
    },
    async loadSetting() {
      // const items = await this.loadSettings()
      // this.contactListSetting = items.find(x => x._id === 'contact_list')
      // if (!this.contactListSetting) {
      //   // const self = this
      //   await this.updateSettings(
      //     {
      //       _id: 'contact_list',
      //       key: 'contact_list',
      //       value: [],
      //     },
      //     async x => {
      //       this.contactListSetting = await this.loadSettingsById(x.id)
      //       this.listContactValue = this.contactListSetting.value
      //     },
      //   )
      // } else {
      //   this.listContactValue = this.contactListSetting.value
      // }
      // this.listContactValue.forEach(list => {
      //   if (list && list.deleteable) {
      //     this.listName.push({
      //       label: list.list_name,
      //       value: list.id,
      //     })
      //   }
      // })
    },
    openContactList() {
      // this.$refs.dialogAddListContact.open('').then(confirm => {
      //   if (confirm.status) {
      //     // const self = this
      //     this.listContactValue.push({
      //       id: this.$nanoid(),
      //       list_name: confirm.name,
      //       deleteable: true,
      //       created_at: new Date().getTime(),
      //       last_modified: new Date().getTime(),
      //     })

      //     this.updateSettings(this.contactListSetting)
      //   }
      // })
      this.tags.push({
        id: uuid.v1(),
        color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        value: '',
        label: '',
        variant: [],
      })
    },
  },
}
</script>
<style scoped>
.scroll {
  overflow-y: scroll;
}
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
</style>
