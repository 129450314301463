var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"mb-0 pb-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"block":"","color":"primary","dense":""},on:{"click":_vm.surveyAddFormOpen}},[_vm._v(" "+_vm._s(_vm.$t('Add Form'))+" ")])],1)],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"item-key":"id","loading":_vm.loadingTable,"headers":_vm.customHeaders,"items":_vm.customDataForm,"footer-props":{
      'items-per-page-text': _vm.$t('rowsPerPageLabel'),
      'items-per-page-options': [5, 10, 25, 50, 75, 100],
    }},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"dense":"","icon":""},on:{"click":function($event){return _vm.selectAction('tags', item)}}},[_c('v-icon',{attrs:{"color":"#1F979E"}},[_vm._v(_vm._s(_vm.icons.mdiLabelVariant))])],1),_c('v-btn',{attrs:{"dense":"","icon":""},on:{"click":function($event){return _vm.selectAction('delete', item)}}},[_c('v-icon',{attrs:{"color":"#1F979E"}},[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))])],1),_c('v-btn',{attrs:{"dense":"","icon":""},on:{"click":function($event){return _vm.selectAction('edit', item)}}},[_c('v-icon',{attrs:{"color":"#1F979E"}},[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1),_c('v-btn',{attrs:{"dense":"","icon":""},on:{"click":function($event){return _vm.selectAction('detail', item)}}},[_c('v-icon',{attrs:{"color":"#1F979E"}},[_vm._v(_vm._s(_vm.icons.mdiEyeOutline))])],1)]}}],null,true)})]}},{key:"item.tags",fn:function(ref){
    var item = ref.item;
return _vm._l((item.tags),function(tag,idx){return _c('v-tooltip',{key:idx,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"fab":"","color":tag.color}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiLabelVariant)+" ")])]}}],null,true)},[_c('span',[_vm._v("Tag Name : "+_vm._s(tag.name))])])})}}],null,true)}),_c('confirm',{ref:"confirm"}),_c('dialogBatchEdit',{ref:"dialogBatchEdit"}),_c('dialogSetTagsForm',{ref:"dialogSetTagsForm",on:{"fetch-form":_vm.fetchForm}}),_c('dialogAddForm',{ref:"dialogAddForm",attrs:{"selected":_vm.selected,"selectedTags":_vm.selectedTags,"tagsList":_vm.tagsList,"isCancelledForm":_vm.isCancelledForm},on:{"fetch-form":_vm.fetchForm,"open-tags":_vm.setTagsOpen,"clear-tagsList":_vm.clearTagsList,"clear-selectedTags":_vm.clearSelectedTags,"cancel-form":_vm.formIsCancelled}}),_c('dialogSetTags',{ref:"dialogSetTags",attrs:{"isCancelledForm":_vm.isCancelledForm},on:{"save-tags":_vm.saveSelectedTags,"fetch-form":_vm.fetchForm,"cancel-form":_vm.cancelForm}}),_c('dialogSelectAddResponse',{ref:"dialogSelectAddResponse",attrs:{"formData":_vm.form}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }