var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","width":"600px"},model:{value:(_vm.dialogEditField),callback:function ($$v) {_vm.dialogEditField=$$v},expression:"dialogEditField"}},[_c('v-card',[_c('v-card-actions',[_c('h3',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('Edit Contact'))+" ")]),_c('v-spacer')],1),_c('v-divider'),_c('div',{staticClass:"text-center"}),_c('v-card-text',{staticClass:"height-dialog"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"my-0 py-0 px-5",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mt-4",attrs:{"rules":[_vm.validators.required],"outlined":"","dense":"","label":("" + (_vm.$t('Name')))},model:{value:(_vm.listEdit.user.name),callback:function ($$v) {_vm.$set(_vm.listEdit.user, "name", $$v)},expression:"listEdit.user.name"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":("" + (_vm.$t('Avatar URL'))),"readonly":""},on:{"click":_vm.openFileManager},model:{value:(_vm.photoLinks),callback:function ($$v) {_vm.photoLinks=$$v},expression:"photoLinks"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":("" + (_vm.$t('Nickname')))},model:{value:(_vm.listEdit.nickname),callback:function ($$v) {_vm.$set(_vm.listEdit, "nickname", $$v)},expression:"listEdit.nickname"}}),[_c('v-combobox',{attrs:{"items":_vm.listEdit.user.phoneNumber,"rules":[_vm.validators.required],"chips":"","label":"Phone Number","multiple":"","outlined":"","readonly":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var phones = ref.phones;
var item = ref.item;
var select = ref.select;
var selectedPhones = ref.selectedPhones;
return [_c('v-chip',_vm._b({attrs:{"input-value":selectedPhones},on:{"click":select}},'v-chip',phones,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}]),model:{value:(_vm.listEdit.user.phoneNumber),callback:function ($$v) {_vm.$set(_vm.listEdit.user, "phoneNumber", $$v)},expression:"listEdit.user.phoneNumber"}})],[_c('v-combobox',{attrs:{"items":_vm.listEdit.email,"rules":[_vm.validators.email],"chips":"","clearable":"","label":"Email","multiple":"","outlined":"","dense":"","deletable-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var emails = ref.emails;
var item = ref.item;
var select = ref.select;
var selectedEmail = ref.selectedEmail;
return [_c('v-chip',_vm._b({attrs:{"clearable":"","input-value":selectedEmail,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',emails,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}]),model:{value:(_vm.listEdit.email),callback:function ($$v) {_vm.$set(_vm.listEdit, "email", $$v)},expression:"listEdit.email"}})],[_c('v-select',{attrs:{"items":_vm.listEdit.tagNames,"required":"","menu-props":{ top: false, offsetY: true, display: 'none' },"dense":"","chips":"","deletable-chips":"","label":"Add Labels","multiple":"","outlined":"","no-data-text":"","readonly":""},on:{"click":function($event){return _vm.openTags(_vm.listEdit)}},model:{value:(_vm.listEdit.tagNames),callback:function ($$v) {_vm.$set(_vm.listEdit, "tagNames", $$v)},expression:"listEdit.tagNames"}})]],2)],1)],1),_c('v-divider',{}),_c('v-card-actions',{staticClass:"text-end"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",on:{"click":function($event){return _vm.cancel()}}},[_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t('cancel')))])]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.saveUpdatedContact(_vm.listEdit.id)}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]),(_vm.loading)?_c('v-progress-circular',{staticClass:"mx-4",attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1),_c('v-snackbar',{staticStyle:{"z-index":"5 !important"},attrs:{"color":"error","top":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.errorTitleValidationSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('Close'))+" ")])]}}]),model:{value:(_vm.errorTitleValidationSnackbar),callback:function ($$v) {_vm.errorTitleValidationSnackbar=$$v},expression:"errorTitleValidationSnackbar"}},[_vm._v(" Please fill out all required fields and make sure you enter a valid email & phone number is required ")]),_c('v-snackbar',{staticStyle:{"z-index":"5 !important"},attrs:{"color":"error","top":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.errorPhoneValidationSnackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('Close'))+" ")])]}}]),model:{value:(_vm.errorPhoneValidationSnackbar.status),callback:function ($$v) {_vm.$set(_vm.errorPhoneValidationSnackbar, "status", $$v)},expression:"errorPhoneValidationSnackbar.status"}},[_vm._v(" Your Phone Number "+_vm._s(_vm.errorPhoneValidationSnackbar.phone)+" Already Registered ")])],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogAlert),callback:function ($$v) {_vm.dialogAlert=$$v},expression:"dialogAlert"}},[_c('v-card',[_c('v-card-title',{attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.$t('warning'))+" ")]),_c('v-divider'),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.dialogAlert = false}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1)],1),_c('dialogSetTags',{ref:"dialogSetTags"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }