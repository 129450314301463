<template>
  <v-card>
    <!-- dialog select list -->
    <v-dialog v-model="dialogOperator" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ `${$t('Tags')}` }}
          </h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />

        <v-card-text>
          <v-select
              v-model="tags"
              :items="formTags"
              item-text="name"
              item-value="id"
              label="Tags"
              multiple
              chips
              dense
              outlined
              append-icon=""
              class="mx-4"
              clearable
            >
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  @click:close="remove(data.item.id)"
                  clearable
                  close
                  small
                  class="py-3 px-2 my-2 mx-1"
                >
                  <strong style="font-size: 12px"
                    ><v-icon :key="data.item.id" :style="{ color: data.item.color }" dense class="py-3">
                      {{ icons.mdiLabelVariant }} </v-icon
                    >{{ data.item.name }}</strong
                  >
                </v-chip>
              </template>
            </v-select>
        </v-card-text>
        <v-divider class="" />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3" @click="cancel">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="#1F979E" class="text-white" @click="saveTags">
            {{ saveText ? saveText : $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog label -->
    <dialogAddListContact ref="dialogAddListContact"></dialogAddListContact>
  </v-card>
</template>

<script>
import { mdiLabel, mdiLabelVariant } from '@mdi/js'
import pouchDbMixin from '../../mixins/pouchDbMixin'

import { default as clientPocketBase } from '@/helpers/pocketbase'
import dialogAddListContact from './DialogAddListContact.vue'

export default {
  components: {
    dialogAddListContact,
  },
  mixins: [pouchDbMixin],
  data: () => ({
    dialogOperator: false,
    resolve: null,
    reject: null,

    dbSettings: null,
    dbRemoteSettings: null,
    contactListSetting: null,
    listContactValue: [],
    listName: [],

    isContact: false,
    contacts: [],
    chats: [],
    contactChat: null,

    selectedList: '',
    saveText: '',
    listOperator: [],

    icons: {
      mdiLabel,
      mdiLabelVariant,
    },
    tags: [],
    tagsChange: [],
    formId: '',
  }),
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
  },
  methods: {
    async open(formId, listOperator = []) {
      this.dialogOperator = true
      this.fetchTagsForm(formId)
    },
    async saveTags() {
      try {
          await clientPocketBase.collection('crm_contact_form').update( this.formId, {
            tags: this.tags,
          })
        } catch (err) {
          console.log(err, 'ini error 123456')
        }
      this.dialogOperator = false
      this.$emit('fetch-form')
    },
    cancel() {
      this.dialogOperator = false
    },
    remove(id) {
      let filtered = this.tags.filter(el => el !== id)
      this.tags = filtered
    },
    async fetchTagsForm(formId) {
      let foundData = await clientPocketBase.collection('crm_contact_form').getOne( formId, {
        expand: 'tags'
      })
      const allTags = await clientPocketBase.collection('crm_tags').getFullList(200)
      this.formTags = allTags
      this.tags = foundData.tags
      this.formId = foundData.id
    },
  },
}
</script>
<style scoped>
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
  .block-condition {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
  }
}
</style>
