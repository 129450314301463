<template>
  <v-card>
    <v-card-text>
      <v-row dense class="mb-0 pb-0">
        <v-col cols="12" md="3">
          <v-menu
            ref="menu"
            v-model="menu"
            :return-value.sync="dates"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Filter"
                :prepend-icon="icons.mdiCalendar"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
              ></v-text-field>
            </template>

            <v-date-picker v-model="dates" range scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="#ffeeee" @click="menu = false">
                <span class="error--text">{{ $t('cancel') }}</span>
              </v-btn>
              <v-btn text color="success" @click="fetchHashtag(dates)"> OK </v-btn></v-date-picker
            >
          </v-menu>
        </v-col>
        <v-icon class="mt-n6 ml-2" style="cursor: pointer" @click="resetDates">{{ icons.mdiClose }}</v-icon>
        <v-spacer></v-spacer>
        <!-- <v-col cols="12" md="auto">
          <v-btn block color="primary" dense @click="openKeyword">
            <v-icon dark left> {{ icons.mdiPlus }} </v-icon>{{ $t('Add Keyword') }}
          </v-btn>
        </v-col> -->
      </v-row>
    </v-card-text>

    <!-- table -->
    <v-row>
      <v-col md="3" lg="3" xl="3" cols="12" class="pt-2 scrollbar" :style="'max-height: 600px'">
        <v-card class="ml-4 my-2" elevation="4">
          <v-card-title class="ml-4 py-3 font-weight-bold" style="color: black !important"> Campaigns </v-card-title>
          <v-card class="mx-6 py-2" elevation="0" style="cursor: pointer" v-for="el in hashtagItems" :key="el.hashtag">
            <v-container class="pt-4 px-4" @click="selectHashtag(el)">
              <v-row>
                <span class="font-weight-700 m-4" style="font-size: 0.85rem">
                  {{ el.hashtag }}
                </span>
              </v-row>
              <span class="font-weight-medium ml-n2" style="font-size: 0.7rem"> {{ el.count }} Contacts </span>
            </v-container>
            <span class="font-italic pt-n4 px-2 mt-n2" @click="openDialogDescription(el)" style="font-size: 0.7rem"
              ><v-icon size="15">{{ icons.mdiPencil }}</v-icon> view or edit description
            </span>
          </v-card>
        </v-card>
      </v-col>

      <v-col>
        <v-card v-if="selectedHashtag" elevation="4" class="mr-2 ml-1">
          <v-row class="mb-2">
            <v-col cols="12" md="12" lg="11" xs="10">
              <span class="d-flex font-weight-bold justify-center" style="color: black !important; font-size: 1.2rem">
                Statistic Campaign {{ selectedHashtag }}
              </span>
            </v-col>
            <v-col cols="12" md="12" lg="11" xs="10">
              <span class="d-flex align-start justify-end">{{ dates.join(',').replace(',', '-') }} </span>
              <vue-apex-charts height="300" type="bar" :options="options" :series="data"></vue-apex-charts>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-else elevation="4" class="mr-2 ml-1">
          <v-row class="mb-2">
            <v-col cols="12" md="12" lg="11" xs="10">
              <span class="d-flex font-weight-bold justify-center" style="color: black !important; font-size: 1.2rem">
                Statistic Campaign All
              </span>
            </v-col>
            <v-col cols="12" md="12" lg="11" xs="10">
              <span class="d-flex align-start justify-end">{{ dates.join(',').replace(',', '-') }} </span>
              <vue-apex-charts height="300" type="bar" :options="options" :series="data"></vue-apex-charts>
            </v-col>
          </v-row>
        </v-card>

        <v-row class="mt-3 mr-1">
          <v-col cols="12" md="12" xs="12">
            <v-data-table
              v-model="selectedTableData"
              :headers="columnsTags"
              :items="listHashtags"
              :loading="loading"
              show-select
              must-sort
              class="text-no-wrap"
              :footer-props="{
                'items-per-page-text': $t('rowsPerPageLabel'),
              }"
            >
              <template #[`item.user`]="{ item }">
                <div class="d-flex align-center" style="white-space: initial; max-width: 20vh">
                  <v-avatar size="40">
                    <v-img
                      :lazy-src="defaultProfPic"
                      :src="getProfPic(item.profile_picture, item)"
                      height="40"
                      width="40"
                    >
                    </v-img>
                  </v-avatar>
                  <div class="d-flex flex-column ms-3">
                    <span
                      style="text-align: left"
                      class="d-block font-weight-semibold text-truncate text--primary phone-number"
                      >{{ item.sender_name }}</span
                    >
                    <span style="text-align: left" class="text-xs">
                      {{ item.sender_id }}
                    </span>
                  </div>
                </div>
              </template>

              <template #[`item.message`]="{ item }">
                <div style="white-space: initial; max-width: 20vh">
                  <span style="text-align: left" class="text-sm truncate">
                    {{ item.content }}
                  </span>
                  <a
                    class="mt-1"
                    style="color: #1f979e"
                    @click="goToRoom(`${item.instance}-${item.sender_id}`, `${item.source_id}`)"
                    >Lihat Chat</a
                  >
                </div>
              </template>

              <template #[`item.channel`]="{ item }">
                <span style="text-align: left" class="text-sm">
                  {{ item.channel }}
                </span>
              </template>

              <template #[`item.date`]="{ item }">
                <span style="text-align: left" class="text-sm">
                  {{ item.formattedTime }}
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <confirm ref="confirm"></confirm>
    <dialogAddKeyword ref="dialogAddKeyword" />
    <v-dialog v-model="dialogDescription" persistent width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">Edit Detail Campaign</h3>
          <v-spacer></v-spacer>
          <!-- <v-btn color="error" class="mr-3 hide-buton" @click="dialogContact = false">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="success" class="hide-buton" @click="saveContact">
            {{ $t('save') }}
          </v-btn> -->
        </v-card-actions>
        <v-divider />

        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" class="my-0 py-0 px-5">
                <v-text-field
                  v-model="hashTag.description"
                  class="mt-4"
                  outlined
                  dense
                  :label="`${$t('Descriptions')}`"
                />
                <v-icon class="vac-svg-button mt-n8 ml-2" color="#5C9DDE" @click="openFileManagerMsg()">{{
                  icons.mdiBullhornOutline
                }}</v-icon>
                <v-row class="mt-2">
                  <v-col v-for="(el, idx) in hashTag.image" :key="idx">
                    <v-img :src="el.preview" width="120px" position="relative">
                      <v-icon position="absolute" top="0" left="0" color="#000000" @click="removeImage(idx)">
                        {{ icons.mdiClose }}
                      </v-icon></v-img
                    >
                  </v-col>
                </v-row>
                <!-- <v-text-field v-model="model.phoneNumber" outlined dense :label="`${$t('Phone')} / WA`" /> -->
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="" />
        <v-card-actions class="text-end">
          <v-spacer></v-spacer>
          <v-btn class="mr-3" @click="dialogDescription = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="primary" @click="saveHashtag()">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
        <v-snackbar v-model="errorHashtagValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
          {{ joinedError }} cannot be empty. Please fill all field!
          <template #action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="
                errorHashtagValidationSnackbar = false
                joinedError = ''
              "
            >
              {{ $t('Close') }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
// import custom
import { fileManagerModal } from '@/helpers/mini-file-manager'
import { default as clientPocketBase } from '@/helpers/pocketbase'
import fileManagerOptions from '@/views/MyAssets/options/optionsFileManager.js'
import '@/views/MyAssets/style.css'
import {
  mdiAutorenew,
  mdiBullhornOutline,
  mdiCalendar,
  mdiCheck,
  mdiClose,
  mdiCog,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiFileDocument,
  mdiInformation,
  mdiLabel,
  mdiLabelVariant,
  mdiPencil,
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'
import { TimeFormater } from '../../helpers'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import confirm from '../component/Confirm.vue'
import dialogAddKeyword from '../component/DialogAddKeyword.vue'
export default {
  name: 'Hashtag',
  components: {
    dialogAddKeyword,
    VueApexCharts,
    confirm,
  },
  mixins: [pouchDbMixin],
  data() {
    return {
      // SEARCH
      searchUser: '',
      data: [],
      options: {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: true,
            tools: {
              download: true, // Disable the built-in download button
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: '"Inter", sans-serif',
        },
        xaxis: {
          categories: [],
        },
        // chart: {
        //   stacked: true,
        //   toolbar: {
        //     show: true,
        //     tools: {
        //       download: true,
        //       selection: false,
        //       zoom: false,
        //       zoomin: false,
        //       zoomout: false,
        //       pan: false,
        //       reset: false,
        //       customIcons: [],
        //     },
        //   },
        // },
        // colors: ['#008FFB', '#00E396', '#CED4DC', '#ff4c51', '#ffb400', '#1f979e'],
        // dataLabels: {
        //   enabled: false,
        // },
        // stroke: {
        //   curve: 'smooth',
        // },
        // fill: {
        //   type: 'gradient',
        //   gradient: {
        //     opacityFrom: 0.6,
        //     opacityTo: 0.8,
        //   },
        // },
        // legend: {
        //   position: 'top',
        //   horizontalAlign: 'left',
        // },
        // xaxis: {
        //   categories: JSON.parse(JSON.stringify(this.rangeDates)),
        // },
      },
      dialogDescription: false,
      // series: [{
      //   name: this.selectedHashtag,
      //   data: [30, 40, 45, 50, 49, 60, 70, 91]
      // }],

      // TABLE
      selectedlabel: '',
      selectedTableData: [],
      loading: false,
      selectedAction: '',
      actions: [
        { label: this.$t('delete'), value: 'delete' },
        { label: this.$t('changeLabel'), value: 'changeLabel' },
      ],
      columnsTags: [
        { text: '', value: 'action', sortable: false },
        { text: 'User', value: 'user', sortable: false, width: '20%' },
        { text: this.$t('Message'), value: 'message' },
        { text: 'Channel', value: 'channel' },
        { text: this.$t('Date'), value: 'date', sortable: false },
      ],
      optionsTable: {
        sortBy: ['last_message'],
        sortDesc: [true],
      },
      icons: {
        mdiCog,
        mdiLabel,
        mdiAutorenew,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiClose,
        mdiCheck,
        mdiInformation,
        mdiFileDocument,
        mdiCalendar,
        mdiLabelVariant,
        mdiTrashCanOutline,
        mdiPlus,
        mdiPencil,
        mdiBullhornOutline,
      },
      show5History: false,

      showChat: '',

      showNotes: false,
      notesData: [],
      dates: [this.$moment().subtract(7, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
      menu: false,
      openTag: false,
      tags: true,
      hashtagItems: [],
      facets: [],
      selectedHashtag: '',
      fieldDate: [],
      listHashtags: [],
      queryInstance: '',
      rangeDates: [],
      description: {},
      hashTag: {
        id: '',
        description: '',
        image: [],
        name: '',
      },
      joinedError: '',
      errorHashtagValidationSnackbar: false,
      isLoadingStats: false,
      instances: [],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    series() {
      if (this.selectedHashtag)
        return [
          {
            name: this.selectedHashtag,
            data: this.fieldDate,
          },
        ]
      return []
    },
    // instances() {
    //   const inst = this.$store.getters['auth/getListInstance']

    //   // //console.log(inst, `INI INSTANCE`)
    //   if (inst) {
    //     return inst.value
    //   }

    //   return []
    // },
    dateRangeText() {
      //console.log(this.dates,'ini tanggal<')
      return this.dates.join(' ~ ')
    },
    defaultProfPic() {
      return `${process.env.VUE_APP_WA_API_URL}ppic/@whatsapp.net/2414214242414/a.jpg`
    },
    // timestamp() {
    //   this.dates.for
    // }
  },
  watch: {},

  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
  },
  async mounted() {
    const instances = await this.$store.dispatch('auth/getAllInstances')
    this.instances = instances?.value || []
    this.setQuery()
    await this.fetchHashtag()
    this.formatDateStats()
  },
  methods: {
    changeDate(e) {
      //console.log(e, 'ini hehehe')
    },
    removeImage(idx) {
      this.hashTag.image = this.hashTag.image.filter((element, index) => index !== idx)
    },
    openFileManagerMsg() {
      fileManagerModal(fileManagerOptions(this.user.sub_id), this.saveFile)
    },
    saveFile(data) {
      data.forEach(el => {
        const res = 'https://fm.prod.marketa.id/uploads/' + el.uploadRelativePath
        el.uploadURL = res
        if (el.mimeGroup === 'text') {
          el.preview = `https://cdn-icons-png.flaticon.com/512/29/29076.png`
        } else if (el.mimeType === 'application/pdf') {
          el.preview = `https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png`
        } else if (el.mimeType.includes('video')) {
          el.preview = 'https://fm.prod.marketa.id/unsafe/200x0/' + el.uploadURL.replace(/^https?:\/\//, '')
          // el.preview = `https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Gnome-video-x-generic.svg/2048px-Gnome-video-x-generic.svg.png`
        } else {
          el.preview = res
        }
      })
      this.hashTag.image = [...this.hashTag.image, ...data]
    },
    openDialogDescription({ hashtag }) {
      const name = hashtag
      this.hashTag = {
        id: '',
        description: '',
        image: [],
        name: '',
      }
      this.dialogDescription = true
      this.hashTag.name = name
      if (this.description[name]) {
        this.hashTag.description = this.description[name].text
        this.hashTag.image = this.description[name].image ? this.description[name].image : []
      }
    },
    async resetDates() {
      this.dates = [this.$moment().subtract(7, 'days').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')]
      this.selectedHashtag = ''
      await this.fetchHashtag()
    },
    goToRoom(roomId, source_id) {
      this.$router.push({ name: 'inbox', query: { roomId, source_id } })
    },
    async saveHashtag() {
      try {
        let arrayError = []
        if (!this.hashTag.description) arrayError.push('Description')
        if (!this.hashTag.image.length) arrayError.push('Image')
        if (arrayError.length) throw arrayError
        // if (!this.hashTag.description || !this.hashTag.image.length) {
        //   throw `Field Empty`
        // }
        try {
          await clientPocketBase
            .collection('crm_hashtags')
            .update(this.$Md5Hash(`${this.hashTag.name}-${this.user.sub_id}`), {
              description: this.hashTag.description,
              image: this.hashTag.image,
            })
        } catch (error) {
          await clientPocketBase.collection('crm_hashtags').create({
            id: this.$Md5Hash(`${this.hashTag.name}-${this.user.sub_id}`),
            hashtag: this.hashTag.name,
            description: this.hashTag.description,
            image: this.hashTag.image,
            subId: this.user.sub_id,
          })
        }
        this.hashTag = {
          id: '',
          description: '',
          image: [],
          name: '',
        }
        this.dialogDescription = false
        this.fetchHashtag()
      } catch (error) {
        console.log(error, 'woi<')
        if (Array.isArray(error)) {
          this.errorHashtagValidationSnackbar = true
          this.joinedError = error.join(', ')
          console.log(this.joinedError, 'weee<')
        }
        // if (error === `Field Empty`) {
        //   this.errorHashtagValidationSnackbar = true
        // }
      }
    },
    setQuery() {
      let query = ''
      for (let i = 0; i < this.instances.length; i++) {
        const instance = this.instances[i]
        if (!instance.broadcast_group && instance.status === 1) {
          if (!query) {
            query = `instance = '${instance._id}'`
          } else {
            query += ` OR instance = '${instance._id}'`
          }
        }
      }
      this.queryInstance = query
    },
    getDateRange(startDate, endDate) {
      const dates = []
      let currentDate = startDate
      const addDays = function (days) {
        const date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)

        return date
      }
      while (currentDate <= endDate) {
        dates.push(new Date(currentDate).toISOString().substring(0, 10))
        currentDate = addDays.call(currentDate, 1)
      }

      // console.log(dates, 'oooooooooooo')
      return dates
    },
    formatDateStats() {
      if (this.dates && this.dates.length === 2) {
        if (new Date(this.dates[0]).getTime() > new Date(this.dates[1]).getTime()) {
          this.rangeDates = this.getDateRange(new Date(this.dates[1]), new Date(this.dates[0]))
        } else {
          this.rangeDates = this.getDateRange(new Date(this.dates[0]), new Date(this.dates[1]))
        }
      } else {
        const startDate = `${new Date((new Date(this.dates[0]).getTime() / 1000 - 72000) * 1000).getFullYear()}-${
          new Date((new Date(this.dates[0]).getTime() / 1000 - 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(this.dates[0]).getTime() / 1000 - 72000) * 1000).getDate()}`
        const endDate = `${new Date((new Date(this.dates[0]).getTime() / 1000 + 72000) * 1000).getFullYear()}-${
          new Date((new Date(this.dates[0]).getTime() / 1000 + 72000) * 1000).getMonth() + 1
        }-${new Date((new Date(this.dates[0]).getTime() / 1000 + 72000) * 1000).getDate()}`
        this.rangeDates = this.getDateRange(new Date(startDate), new Date(endDate))
      }
      // if (this.selectedHashtag) {
      //   this.options = {
      //     legend: {
      //       show: true,
      //       position: 'top',
      //       horizontalAlign: 'left',
      //       fontSize: '14px',
      //       fontFamily: '"Inter", sans-serif',
      //     },
      //     dataLabels: {
      //       enabled: false,
      //     },
      //     stroke: {
      //       show: false,
      //       curve: 'straight',
      //     },
      //     xaxis: {
      //       categories: JSON.parse(JSON.stringify(this.rangeDates)),
      //     },
      //   }
      // }
    },
    async selectHashtag(hashtag) {
      if (hashtag) {
        if (typeof hashtag === 'string') {
          this.selectedHashtag = hashtag
        } else this.selectedHashtag = hashtag.hashtag
      }
      this.fieldDate = []
      this.formatDateStats()

      const timestamp = [this.$moment().subtract(7, 'days').unix() * 1000, this.$moment().unix() * 1000]

      const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}messages/select-hashtag`
      const body = {
        sub_id: this.user.sub_id,
        queryInstance: this.queryInstance,
        timestamp: [
          new Date(new Date(this.dates[0]).setHours(0, 0, 0, 0)).getTime(),
          new Date(new Date(this.dates[1]).setHours(23, 59, 59, 59)).getTime(),
        ],
        hashtag: this.selectedHashtag,
      }
      try {
        const { data: objHashtag } = await this.$axios.post(endpoint, body)
        // this.options = {}

        if (objHashtag.status && objHashtag.data && this.selectedHashtag) {
          this.listHashtags = JSON.parse(JSON.stringify(objHashtag.data.hashtag))
          for (let i = 0; i < this.listHashtags.length; i++) {
            const ele = this.listHashtags[i]
            const channelId = ele.chatId.split('-')
            const instance = this.instances.find(ele => ele._id === channelId[0])
            ele.formattedTime = this.$moment(ele.timestamp._seconds * 1000).format('LL')
            if (instance?.label) {
              ele.channel = instance.label
            }
          }
          const date = objHashtag.data.facet
          for (let i = 0; i < this.rangeDates.length; i++) {
            const dateo = this.rangeDates[i]
            const index = date.findIndex(ele => ele.date === dateo)
            if (index > -1) {
              this.fieldDate.push(date[index].count)
            } else {
              this.fieldDate.push(0)
            }
          }
          this.data = JSON.parse(JSON.stringify(this.series).replace(/#/g, ''))
          this.options.xaxis.categories.splice(0, this.options.xaxis.categories.length, ...this.rangeDates)
          console.log('DATA:' + JSON.stringify([this.data, this.options]))
        } else if (objHashtag.status && objHashtag.data) {
          /// Set list messages containing hashtag
          this.listHashtags = JSON.parse(JSON.stringify(objHashtag.data.hashtag))
          for (let i = 0; i < this.listHashtags.length; i++) {
            const ele = this.listHashtags[i]
            const channelId = ele.chatId.split('-')
            const instance = this.instances.find(ele => ele._id === channelId[0])
            ele.formattedTime = this.$moment(ele.timestamp._seconds * 1000).format('LL')
            if (instance?.label) {
              ele.channel = instance.label
            }
          }
          const array = objHashtag.data.facet
          // Create a map to group the data by hashtag
          let hashtagData = {}
          array.forEach(item => {
            let hashtag = item.hashtag[0]
            if (!hashtagData.hasOwnProperty(hashtag)) {
              hashtagData[hashtag] = Array(this.rangeDates.length).fill(0)
            }
          })

          // Loop through the range of dates and populate the data array
          this.rangeDates.forEach((date, index) => {
            array.forEach(item => {
              if (item.date === date) {
                let hashtag = item.hashtag[0]
                let count = parseInt(item.count)
                hashtagData[hashtag][index] = count
              }
            })
          })

          // Create the series array for ApexCharts
          let series = []
          for (let hashtag in hashtagData) {
            series.push({
              name: hashtag,
              data: hashtagData[hashtag],
            })
          }
          this.data = JSON.parse(JSON.stringify(series).replace(/#/g, ''))
          this.options.xaxis.categories.splice(0, this.options.xaxis.categories.length, ...this.rangeDates)
          console.log('DATA:1:' + JSON.stringify([this.data, this.options]))
        }
      } catch (error) {
        console.log(error, 'error select hashtag')
      }
    },
    dateFormater(dt) {
      return TimeFormater.dateFormater(dt)
    },
    getProfPic(img, item) {
      if (img) return img

      return `${process.env.VUE_APP_WA_API_URL}ppic/${item.phone_number}@whatsapp.net/${item.phone_number}/a.jpg`
    },
    async fetchHashtag(dates = []) {
      this.hashtagItems = []
      this.facets = []
      this.dates.sort()
      if (!dates.length) {
        dates = this.dates
      }
      this.$refs.menu.save(this.dates)
      this.menu = false
      const timestamp = dates.map((ele, index) => {
        // return new Date(ele).setHours(0, 0, 0)
        if (index === 1) {
          // return this.$moment(ele).unix() * 1000 + 86399000
          return new Date(ele).setHours(23, 59, 59)
        } else {
          // return this.$moment(ele).unix() * 1000
          return new Date(ele).setHours(0, 0, 0)
        }
      })

      this.formatDateStats()

      const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}messages/hashtag`
      const body = {
        sub_id: this.user.sub_id,
        queryInstance: this.queryInstance,
        timestamp,
      }
      try {
        const { data: listHashtags } = await this.$axios.post(endpoint, body)
        let hashtagItems = []
        let hits = listHashtags.data || []
        for (let i = 0; i < hits.length; i++) {
          const hashtags = hits[i].hashtag
          for (let j = 0; j < hashtags.length; j++) {
            const hashtag = hashtags[j]
            if (hashtagItems.findIndex(item => item === hashtag) === -1) {
              hashtagItems.push({ hashtag, count: hits[i].count })
            }
            try {
              let foundHashtag = await clientPocketBase
                .collection('crm_hashtags')
                .getOne(this.$Md5Hash(`${hashtag}-${this.user.sub_id}`))
              if (foundHashtag) {
                this.description[hashtag] = { text: '', image: [] }
                this.description[hashtag].text = foundHashtag.description
                this.description[hashtag].image = foundHashtag.image
              }
            } catch (error) {
              continue
            }
          }
        }
        this.hashtagItems = [...hashtagItems]
        await this.selectHashtag(
          this.selectedHashtag
            ? typeof this.selectedHashtag === 'string'
              ? this.selectedHashtag
              : this.selectedHashtag.hashtag
            : '',
        )
      } catch (err) {
        console.log(err, 'err hashtag<')
      }
      if (this.selectedHashtag && !this.hashtagItems.find(ele => ele.hashtag === this.selectedHashtag)) {
        this.fieldDate = []
        this.listHashtags = []
        // this.selectedHashtag = ''
      }
    },
    openKeyword() {
      this.$refs.dialogAddKeyword.open()
    },
  },
}
</script>
<style scoped>
.scrollbar {
  /* flex: 1;
  position: relative;
  max-width: 100%;
  cursor: pointer;
  padding: 0; */
  overflow-y: scroll;
  width: 1px;
}
.card-note {
  background-color: #ffffff67;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #adadad;
}

.v-data-table-header__icon {
  opacity: 1;
}

@media only screen and (max-width: 578px) {
  .sm-display {
    display: none;
  }

  .last-served-image {
    display: none;
  }

  .last-served {
    font-size: 12px;
  }

  .phone-number {
    font-size: 12px;
  }
}

@media only screen and (min-width: 578px) {
  .sm-display-mobile {
    display: none;
  }
}
.bottom-action {
  display: none;
}
.padding-hero {
  padding-left: 80px;
  padding-right: 80px;
}
.mobile-tag {
  display: none;
}
/* Mobile */

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .bottom-action {
    display: inherit;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
    width: 200px;
  } */

  .vac-col-messages .vac-container-scroll {
    /* background: var(--chat-content-bg-color); */
    flex: 1;
    overflow-y: auto;
    overflow-anchor: none !important;
    margin-right: 1px;
    margin-top: 60px;
    -webkit-overflow-scrolling: touch;
  }
}
</style>
